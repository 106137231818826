import './footer.css';
import { React, Link, Locale, useTranslation, useEffect, useMemo, useDispatch, useSelector, useNavigate } from '../../libraries';
import { CustomLink, CustomParagraph, CustomIcon } from '../index';
import { AppleStoreImage, PlayStoreImage, SuraLogo } from '../../assets/Images';
import { HomeRoutes } from '../../routes/index';
import { fetchUserFooterInformation } from '../../redux/index';
import { CustomImage } from '../../lazyComponent';
import { urlFormat } from '../../customHooks/index';
import { useState } from 'react';
import LocationChecker from '../../components/middleware/locationChecker'


const Footer = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const currentYear = new Date().getFullYear();
  const { t } = Locale();
  const navigate = useNavigate();
  const currentLocation = sessionStorage.getItem('location')
  const [targetPath, setTargetPath] = useState('');
  const [showLocationChecker, setShowLocationChecker] = useState(false);
  const userFooterDetails = useSelector((state) => state.userFooterDetails.userFooterDetails);
  const socialLinks = useSelector((state) => state.userFooterDetails.socialLinks);
  useEffect(() => {
    if (userFooterDetails && Object.keys(userFooterDetails).length === 0) {
      dispatch(fetchUserFooterInformation());
    }
  }, [userFooterDetails, dispatch]);

  const address = i18n.language === 'en' ? userFooterDetails?.address : userFooterDetails?.address_thai;
  const quotes = i18n.language === 'en' ? userFooterDetails?.quote : userFooterDetails?.quote_thai;


  const handleLinkClick = (e, path) => {
    e.preventDefault();
    if (currentLocation) {
      navigate(path);
    } else {
      setTargetPath(path);
      setShowLocationChecker(true); 
    }
  };

  const appStoreLinks = [
    { src: AppleStoreImage, alt: 'applestore', url: 'https://apps.apple.com/us/app/sura360/id6502856298' },
    { src: PlayStoreImage, alt: 'playstore', url: 'https://play.google.com/store/apps/details?id=com.sura360.sura_user' },
  ];

  const footerLinks = useMemo(
    () => [
      { path: `${HomeRoutes.home}`, text: t('footer.home'), target: '_self' },
      { path: `${HomeRoutes.about}`, text: t('footer.aboutUs') },
      { path: `${HomeRoutes.services}/${urlFormat('Best Sellers')}`, text: t('footer.services') },
      { path: `${HomeRoutes.contact}`, text: t('footer.contactUs'), target: '_self' },
      { path: `${HomeRoutes.blog}`, text: t('field.blog'), target: '_self' },
      { path: `${HomeRoutes.faqs}`, text: t('footer.faq'), target: '_self' },
      { path: `${HomeRoutes.businessPolicy}`, text: t('footer.businessPolicy'), target: '_self' },
      { path: `${HomeRoutes.cancelPolicy}`, text: t('footer.cancellationPolicy'), target: '_self' },
      { path: `${HomeRoutes.termCondition}`, text: t('footer.term&Condition'), target: '_self' },
      { path: `${HomeRoutes.privacy}`, text: t('footer.privacy'), target: '_self' },
    ],
    [t]
  );

  useEffect(() => {
    const handleScriptLoad = () => {
      const targetElement = document.getElementById("Certificate-banners");
      if (targetElement) {
        targetElement.innerHTML = `
          <a 
            title="กรมพัฒนาธุรกิจการค้า Trustmarkthai" 
            style="padding-left: 5px;" 
            href="https://www.trustmarkthai.com/callbackData/popup.php?data=33a3-32-6-1297a7c4df3c90f305c12d5142a0125757e45dd624&markID=firstmar" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <img 
              style="max-width: 100%; max-height: 75px;" 
              alt="กรมพัฒนาธุรกิจการค้า Trustmarkthai" 
              src="https://www.trustmarkthai.com/trust_banners/bns_registered.png"
            />
          </a>
        `;
      }
    };
  
    const script = document.createElement("script");
    script.id = "dbd-init";
    script.src = "https://www.trustmarkthai.com/callbackData/initialize.js?t=33a3-32-6-1297a7c4df3c90f305c12d5142a0125757e45dd624";
    script.async = true;
    script.onload = handleScriptLoad;
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  


  const handleLocationCheckComplete = (permissionGranted) => {
    setShowLocationChecker(false); 
    if (permissionGranted) {
      navigate(targetPath);
    }
  };

  return (<>
    <footer className="footer footer-outer-container">
      <div>
        <div className="row justify-content-around">
          <div className="col-md-3 col-lg-4 col-xl-3 main-col">
            <div className="footer-heading">
              <div>
                <CustomImage className="sura-logo img-fluid" src={SuraLogo} alt="logo" height={'53px'} width={'100px'} />
                <CustomParagraph className="footer-paragraph" text={quotes} />
              </div>
            </div>
            <div className="certificate-div" id="Certificate-banners"></div>
          </div>
          <div className="col-md-2 col-lg-1 col-xl-2">
            <div className="footer-links-vertical">
              {footerLinks.slice(0, 5).map((link, index) => (
                <div
                  key={index}
                  className={"footer-link"}
                  onClick={(e) => handleLinkClick(e, link.path)}
                >
                  {link.text}
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-3 col-lg-3">
            <div className="footer-links-vertical">
              {footerLinks.slice(5).map((link, index) => (
                <div
                  key={index}
                  className={"footer-link"}
                  onClick={(e) => handleLinkClick(e, link.path)}
                >
                  {link.text}
                </div>
              ))}
            </div>
          </div>
          <div className="footer-contact col-md-4 col-lg-3 mb-md-0">
            <div className="footer-paragraph contact-link">
              <div>
                <span className="d-flex">
                  <span className="me-2">{CustomIcon.location}</span>
                  <span>{address}</span>
                </span>
              </div>
            </div>
            <div className="footer-paragraph contact-link">
              <Link to={`mailto:${userFooterDetails?.email}`} title="Share by Email">
                {CustomIcon.mail} &nbsp;{userFooterDetails?.email}
              </Link>
            </div>
            <div className="footer-paragraph contact-link">
              <Link to={`tel:${userFooterDetails?.phone_number}`}>
                {CustomIcon.phone} &nbsp;{userFooterDetails?.phone_number}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className='copyRight-footer row'>
        <div className="col-md-4 col-12 text-center text-white copy-rigth-section mb-2 d-flex text-center">
          <CustomLink
            path="https://sura360.com"
            text={`© ${currentYear} All Rights Reserved`}
            className="footer-link"
          />
        </div>
        <div className='col-md-4 col-12 text-center mb-3 mt-2'>
          {socialLinks.map((link) => (
            link.active && (<a key={link.id} href={link.link} target="_blank" rel="noopener noreferrer">
              <i className={`bi bi-${link.name.toLowerCase()} social-footer-icon`}></i>
            </a>)
          ))}
        </div>

        <div className='col-md-4 col-12 d-flex justify-content-center'>
          {appStoreLinks.map((link, index) => (
            <Link key={index} to={link.url} target="_blank" rel="noopener noreferrer">
              <img src={link.src} alt={link.alt} width={"100px"} height={"auto"} className='img-fluid app-store' />
            </Link>
          ))}
        </div>
      </div>
    </footer>
    {showLocationChecker && (
      <LocationChecker
        path={targetPath}
        onComplete={handleLocationCheckComplete}
      />
    )}
  </>
  );
};
export default Footer;