import { dynamicTimeZone } from "../../../customHooks";
import { useEffect, useState, DateTime } from "../../../libraries";
import { handleErrorResponse, handleSuccessResponse, editBookings } from "../../../services";
const useEditUserBooking = ({
  closeEditBookingModal,
  booking,
  setLoader,
  bookindata
}) => {
  const [formValues, setFormValues] = useState({
    customerName: "",
    serviceName: "",
    duration: "",
    listedPrice: "",
    paidAmount: "",
    discount: "",
    paymentType: "",
    status: "",
    scheduleDate: "",
    teamMember: "",
    scheduleTime: "",
    delivery_type: ""
  });
  const [formErrors, setFormErrors] = useState({
    customerName: "",
    serviceName: "",
    duration: "",
    listedPrice: "",
    paidAmount: "",
    discount: "",
    paymentType: "",
    status: "",
    scheduleDate: "",
    teamMember: "",
    scheduleTime: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dayName, setDayName] = useState();
  const [timeSlots, setTimeSlots] = useState();
  const [bussinessHours, setBussinessHours] = useState();
  const [vendorOpenignHours, setVendorOpenignHours] = useState();

  const handleChange = (name, value) => {
    if (name === "scheduleDate") {
      setIsModalVisible(true);
    }

    setFormValues({ ...formValues, [name]: value });
  };

  const handleDateSelect = async (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    const dateObj = new Date(dateString);
    const options = { weekday: "long" };
    const dayName = dateObj
      .toLocaleDateString("en-US", options)
      .toLocaleLowerCase();
    setDayName(dayName);
    setFormValues((prevValues) => ({
      ...prevValues,
      scheduleDate: dateString,
    }));
  };

  const handleCancel = () => {
    setIsModalVisible(false);

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
    if (Object.values(errors).every((value) => value === "")) {
      closeEditBookingModal();
      try {
        let formData = appendData();
        let params = {
          id: booking,
          formData: formData,
        };
        setLoader(true)
        const response = await editBookings(params);
        setLoader(false)
        bookindata()
        handleSuccessResponse(response, response.data.message);

      } catch (err) {
        handleErrorResponse(err);
        closeEditBookingModal();

      }
      finally {
        setLoader(false)
      }
    }
  };

  const appendData = () => {
    let formData = new FormData();
    formData.append("status", formValues.status.toLocaleLowerCase());
    formData.append("booking_date", formValues.scheduleDate);
    formData.append("booking_time", formValues.scheduleTime);
    formData.append("delivery_type", formValues?.delivery_type)

    return formData;
  };

  const validate = (values) => {
    const errors = {};
    if (!values.status) {
      errors.status = "Status is required";
    }
    if (values.scheduleDate === '') {
      errors.scheduleDate = "Please select this field"
    }
    return errors;
  };

  const vendorOpenignHoursFunction = (bussiness_hours) => {
    setBussinessHours(bussiness_hours);
    let newBuissnessDay = {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    };
    if (bussiness_hours) {
      setVendorOpenignHours((prevState) => ({
        ...prevState,
        monday:
          bussiness_hours.monday && bussiness_hours.monday.shop_open === "true"
            ? true
            : false,
        tuesday:
          bussiness_hours.tuesday &&
            bussiness_hours.tuesday.shop_open === "true"
            ? true
            : false,
        wednesday:
          bussiness_hours.wednesday &&
            bussiness_hours.wednesday.shop_open === "true"
            ? true
            : false,
        thursday:
          bussiness_hours.thursday &&
            bussiness_hours.thursday.shop_open === "true"
            ? true
            : false,
        friday:
          bussiness_hours.friday && bussiness_hours.friday.shop_open === "true"
            ? true
            : false,
        saturday:
          bussiness_hours.saturday &&
            bussiness_hours.saturday.shop_open === "true"
            ? true
            : false,
        sunday:
          bussiness_hours.sunday && bussiness_hours.sunday.shop_open === "true"
            ? true
            : false,
      }));
    } else {
      setVendorOpenignHours(newBuissnessDay);
    }
  };

  const generateTimeSlots = async () => {
    if (dayName) {
      const selectedDayData = await bussinessHours[dayName.toLowerCase()];
      if (!selectedDayData || !selectedDayData.shop_open) {
        setTimeSlots([]);
        return;
      }
      const openTime = selectedDayData.open;
      const closeTime = selectedDayData.close;
      const startTime = DateTime.fromFormat(openTime, 'HH:mm', { zone: dynamicTimeZone() });
      const endTime = DateTime.fromFormat(closeTime, 'HH:mm', { zone: dynamicTimeZone() });
      const timeSlots = [];
      let currentTime = startTime;
      while (currentTime <= endTime) {
        const formattedTime = currentTime.toFormat('HH:mm');
        timeSlots.push(formattedTime);
        currentTime = currentTime.plus({ minutes: 30 });
      }
      setTimeSlots(timeSlots);
    } else {
      const timeSlots = [];
      let currentTime = DateTime.now().setZone(dynamicTimeZone()).startOf('day');
      for (let i = 0; i < 48; i++) {
        const formattedTime = currentTime.toFormat('HH:mm');
        timeSlots.push(formattedTime);
        currentTime = currentTime.plus({ minutes: 30 });
      }

      setTimeSlots(timeSlots);
    }
  };

  useEffect(() => {
    if (dayName) generateTimeSlots();
  }, [dayName]);

  return {
    setDayName,
    timeSlots,
    vendorOpenignHoursFunction,
    vendorOpenignHours,
    handleDateSelect,
    formValues,
    setFormValues,
    handleChange,
    handleSubmit,
    formErrors,
    handleCancel,
    isModalVisible,
    setFormErrors
  };
};
export default useEditUserBooking;