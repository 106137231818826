import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar } from "../../pages";
import { BusinessRoutes, HomeRoutes } from "../../routes/index";

const NavbarLogic = ({
  children,
  showInnerHeader,
  setPrivacy,
  handleAddToCartCount,
  showDrawer,
  showSort,
  serviceType,
  handleNavbarCityChange
}) => {
  const navigate = useNavigate();
  const role = localStorage.getItem("role") || "";
  const [itemCount, setItemCount] = useState(Number(localStorage.getItem("cartCount")) || 0);

  // Cart count update
  useEffect(() => {
    if (role === "Customer") {
      setItemCount(Number(localStorage.getItem("cartCount")) || 0);
    }
  }, [role, handleAddToCartCount]);

  // Home page navigation
  const handleHomePage = useCallback((e) => {
    e.preventDefault();
    localStorage.removeItem("isSection");
    navigate(HomeRoutes.home);
  }, [navigate]);

  // Dashboard navigation
  const handleDashboard = useCallback((e) => {
    e.preventDefault();
    const sectionKey = role === "TeamMember" ? "isVendorProfile" : "isDashboard";
    localStorage.setItem("isSection", sectionKey);
    navigate(`${BusinessRoutes.bussiness}${role === "TeamMember" ? BusinessRoutes.profile : BusinessRoutes.dashboard}`);
  }, [navigate, role]);

  return (
    <Navbar
      handleDashboard={handleDashboard}
      showInnerHeader={showInnerHeader}
      showSideBar={showDrawer}
      showSortBar={showSort}
      handleHomePage={handleHomePage}
      children={children}
      products={itemCount}
      setPrivacy={setPrivacy}
      serviceType={serviceType}
      handleNavbarCityChange={handleNavbarCityChange}
    />
  );
};

export default React.memo(NavbarLogic);
