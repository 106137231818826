import './Payout.css';
import { Locale, useLocation, useEffect, React } from '../../../libraries';
import { CustomSpinner, Heading } from '../../../common';
import { CurrencyDisplay } from '../../../jsonData';
import { UnderReviewLogic, PayoutLogic } from '../../component';
import { VendorHeader } from '../index';
const Payout = ({deletionRequest}) => {
  const location = useLocation();
  const { t } = Locale();
  const { isOrganizationStatus, isLoader, data, openSummaryPage } =
    PayoutLogic();
  const selectedLanguage = localStorage.getItem('i18nextLng');
  const isSection = localStorage.getItem('isSection');

  useEffect(() => {
    if (isSection !== 'isBusinessPayout') {
      localStorage.setItem('isSection', 'isBusinessPayout');
    }
  }, [location.pathname]);

  const getMonthName = (obj) => {
    return selectedLanguage === 'thai' ? obj?.month_thai : obj.month;
  };

  return (
    <>
      <div className={`heading-outer-div  ${deletionRequest && 'vendor-deletionRequest-user-header'}`}>
        <VendorHeader />
        <Heading text={t('vendor.payout')} icon={'bi bi-wallet'} />
        <div className="after-heading-outer-div">
          {isOrganizationStatus !== 'verified' ? (
            <UnderReviewLogic />
          ) : (
            <div className={`user-common-box`}>
              <div className="user-common-box-inner">
                <div className="user-common-box-fix-content row-box m-0 mb-3">
                  <div className="user-booking-tab-outer-div vendor-payout-summary">
                    <span
                      className={`user-bookings-tab ms-1 user-bookings-tab-active`}
                    >
                      {t('payout.payoutHistory')}
                    </span>
                  </div>
                </div>
                <div
                  className={`user-inner-content-scrollar-after-fix-content vendor-payout-section  ${!isLoader ? `user-inner-content-scrollar` : 'd-none'}`}
                >
                  {data &&
                    data.length > 0 &&
                    data.map((obj, index) => (
                      <div
                        className="venodr-payout-history-table-row"
                        key={index}
                      >
                        <div className="vendor-payout-monthName">
                          {getMonthName(obj)}
                        </div>
                        <div className="vendor-payout-revenue">
                          <div className="vendor-payout-currency">
                            <CurrencyDisplay value={obj.total_revenue} />
                          </div>
                          <div className="vendor-payout-next-icon">
                            <i
                              className="bi bi-caret-right-fill"
                              onClick={(e) =>
                                openSummaryPage(e, obj?.month, obj?.month_thai)
                              }
                            ></i>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
          {isLoader && <CustomSpinner />}
        </div>
      </div>
    </>
  );
};
export default Payout;