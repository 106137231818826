import { useMemo } from "../libraries";
import { dynamicTimeZone } from "./timeZone";
export const useUserRoleAndSection = () => {
    const role = useMemo(() => localStorage.getItem('role'), []);
    const isSection = localStorage.getItem('isSection');
    return { role, isSection };
};

export const getFormatedDateMonthName = (date) => {
    return new Date(date).toLocaleDateString(
      "en-GB",
      {
        month: "short",
        day: "2-digit",
        year: "numeric",
        timeZone: dynamicTimeZone(),
      }
    );
  };