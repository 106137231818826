import { React, Link, Locale, useEffect, useState, useRef } from "../../libraries";

const WebCategorySection = ({
    handleCategoryClickFun,
    items,
    onCategoryClick,
    subCategoriesDatas,
    onSubCategoryClick,
    handleMouseLeave,
    onBestSellerEnter
}) => {
    const { t } = Locale();
    const selectedLanguage = localStorage.getItem("i18nextLng");
    const submenu1Ref = useRef({});
    useEffect(() => {
        submenu1Ref.current = {};
    }, []);

    useEffect(() => {
        let lastScrollTop = 0;
        const delta = 5;
        const headerElement = document.querySelector("header");
        const navbarHeight = headerElement.offsetHeight;

        const handleScroll = () => {
            const st = window.scrollY;
            if (Math.abs(lastScrollTop - st) <= delta) return;

            if (st > lastScrollTop && st > navbarHeight) {
                headerElement.classList.remove("nav-down");
                headerElement.classList.add("nav-up");
            } else {
                if (st + window.innerHeight < document.documentElement.scrollHeight) {
                    headerElement.classList.remove("nav-up");
                    headerElement.classList.add("nav-down");
                }
            }
            lastScrollTop = st;
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const [activeItemId, setActiveItemId] = useState(null); 

    const handleItemClick = (itemId) => {
        setActiveItemId(itemId);
    };

    return <>


        <ul className="menu-main">
            {items.map((item) => (
                <li
                    key={item.id}
                    className={`menu-item-has-children ${activeItemId === item.id ? "active" : ""
                        }`}
                    onClick={(e) => {
                        handleItemClick(item.id);
                        handleCategoryClickFun(
                            e,
                            item.name_en,
                            item.name_en,
                            item.name_thai
                        )

                    }}
                    onMouseEnter={() =>
                        onCategoryClick(item.id, item.name_en, item.name_thai)
                    }
                    onMouseLeave={() => handleMouseLeave()}
                >
                    <Link className="category">
                        {selectedLanguage === "thai" &&
                            selectedLanguage !== undefined &&
                            selectedLanguage !== null
                            ? item.name_thai
                            : item.name_en}
                        &nbsp;&nbsp;<i className="bi bi-chevron-down"></i>
                    </Link>
                    {subCategoriesDatas?.length > 0 && (
                        <>
                            <div
                                className="sub-menu single-column-menu"
                                ref={(el) => (submenu1Ref.current[item.id] = el)}
                            >
                                <ul className="submenu-main">
                                    {subCategoriesDatas.map((subItem) => (
                                        <li
                                            key={subItem.id}
                                            className="menu-item-has-children colorBlack"
                                            onClick={(e) =>
                                                onSubCategoryClick(
                                                    e,
                                                    subItem.id,
                                                    subItem.name_en,
                                                    subItem.name_thai
                                                )
                                            }
                                        >
                                            <Link>
                                                {selectedLanguage === "thai" &&
                                                    selectedLanguage !== undefined &&
                                                    selectedLanguage !== null
                                                    ? subItem.name_thai
                                                    : subItem.name_en}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </>
                    )}
                </li>
            ))}
            <li
                className="menu-item-has-children best-seller"
                onClick={(e) => {
                    handleCategoryClickFun(
                            e,
                            "Best Sellers",
                            "Best Sellers",
                            "ขายดี"
                        )
                }}
                onMouseEnter={() =>
                    onBestSellerEnter("36", "Best Sellers", "ขายดี")
                }
            >
                <Link>
                    {t("breadcrumb.bestSeller")}&nbsp;&nbsp;
                    <i className="bi bi-chevron-down"></i>
                </Link>
                {subCategoriesDatas?.length > 0 && (
                    <>
                        <div className="sub-menu single-column-menu">
                            <ul className="submenu-main">
                                {subCategoriesDatas.map((subItem) => (
                                    <li
                                        key={subItem.id}
                                        className="menu-item-has-children colorBlack"
                                        onClick={(e) =>
                                            onSubCategoryClick(
                                                e,
                                                subItem.id,
                                                subItem.name,
                                                subItem.name_thai
                                            )
                                        }
                                    >
                                        <Link>
                                            {selectedLanguage === "thai" &&
                                                selectedLanguage !== undefined &&
                                                selectedLanguage !== null
                                                ? subItem.name_thai
                                                : subItem.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </>
                )}
            </li>
        </ul>
    </>
}

export default WebCategorySection;