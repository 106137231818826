export const urlFormat = (value) => {
  return value
    ? value.replace(/%/g, '-')
      .replace(/ /g, '-')
      .replace(/&/g, 'and')
    : value;
};

export const urlDeFormat = (value) => {
  return value
    ? value.replace(/-/g, ' ')
      .replace(/and/g, '&')
      .replace(/-/g, '%')
    : value;
};

export const organizationUrlFormat = (value) => {
  return value
    ? value.replace(/%/g, '-')
      .replace(/ /g, '-')
    : value;
};

export const organizationUrlDeFormat = (value) => {
  return value
    ? value.replace(/-/g, ' ')
      .replace(/-/g, '%')
    : value;
};