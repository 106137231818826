import React, { useEffect, useRef, useState } from 'react';
import lozad from 'lozad';
import { DefaultServiceImg } from '../../assets/Images';

const CustomImage = ({
  src,
  className = '',
  alt = '',
  height,
  width,
  onClick,
  isLCP = false,
}) => {
  const imgRef = useRef(null);
  const [currentSrc, setCurrentSrc] = useState(
    isLCP ? src || DefaultServiceImg : DefaultServiceImg
  );
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!src) {
      setCurrentSrc(DefaultServiceImg);
      return;
    }

    if (isLCP) {
      // Handle LCP images (load immediately)
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setCurrentSrc(src);
        setIsLoaded(true);
      };
      img.onerror = () => {
        setCurrentSrc(DefaultServiceImg);
        setIsLoaded(true);
      };
    } else if (imgRef.current) {
      // Handle lazy loading for non-LCP images
      const observer = lozad(imgRef.current, {
        rootMargin: '200px 0px',
        threshold: 0.01,
        loaded: (el) => {
          const imgSrc = el.getAttribute('data-src');
          if (imgSrc) {
            el.src = imgSrc;
            el.onload = () => {
              setCurrentSrc(imgSrc);
              setIsLoaded(true);
              el.classList.add('loaded');
            };
            el.onerror = () => {
              el.src = DefaultServiceImg;
              setIsLoaded(true);
            };
          }
        }
      });
      observer.observe();

      return () => {
        if (observer.observer) {
          observer.observer.disconnect();
        }
      };
    }
  }, [src, isLCP]);

  return (
    <img
      ref={imgRef}
      src={currentSrc}
      data-src={!isLCP ? src : undefined}
      width={width}
      height={height}
      alt={alt}
      className={`${className} ${isLoaded ? 'loaded' : 'loading'}`}
      loading={isLCP ? 'eager' : 'lazy'}
      onClick={onClick}
      decoding="async"
    />
  );
};

export default React.memo(CustomImage);