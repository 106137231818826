import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { decryptData, encryptData, organizationUrlDeFormat, organizationUrlFormat } from "../../customHooks";
import { addCartItem, handleErrorResponse, handleSuccessResponse, fetchRelatedServiceData, serviceIdVendorDetails, showCartList } from "../../services";
import { HomeRoutes } from "../../routes";
import { AddOnFavouriteList, RemoveFromFavouriteList } from "../addToFavourite/addToFavouriteLogic";
import { toast } from "react-toastify";

const ServiceSectionLogic = () => {
  const selectedLanguage = localStorage.getItem("i18nextLng");
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { serviceId, serviceName } = params;
  const decryptedServiceName = organizationUrlDeFormat(serviceName)
  const role = localStorage.getItem("role");
  const cartCount = localStorage.getItem("cartCount") || 0;
  const [cartItem, setCartItem] = useState();
  const [serviceData, setServiceData] = useState();
  const [relatedServices, setRelatedService] = useState({
    services: [],
    totalPages: 0,
  });
  const [relatedServicesLoader, setRelatedServiceLoader] = useState(false);
  const [loader, setLoader] = useState(true)
  const [previousBreadCrumb, setPreviousBreadCrumb] = useState()
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState("");
  const [commonId, setCommonId] = useState("");
  const [userType, setUserType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const per_page = 4;

  useEffect(() => {
    const fetchData = async () => {
      await getServiDetails();
      await addtocart()
    };
    fetchData();

  }, [location.pathname]);

  useEffect(() => {
    const fetchRelatedData = async () => {
      await getRelatedService();
    };
    fetchRelatedData();
  }, [location.pathname]);

  useEffect(() => {
    setPreviousBreadCrumb(location?.state?.previousBreadcrumb)
    if (localStorage.getItem('isSection')) {
      localStorage.removeItem('isSection');
    }
  }, [location.pathname]);

  const getServiDetails = async () => {
    try {
      setLoader(true)
      const decryptedServiceId = decryptData(serviceId);
      if (decryptedServiceId) {
        const response = await serviceIdVendorDetails(decryptedServiceId);
        if ((response?.data?.service?.service_name_en).trim() === decryptedServiceName.trim()) {
          setServiceData(response.data);
        }

      }
    }
    catch (err) {

    }
    finally {
      setLoader(false)
    }
  }

  const getRelatedService = async () => {
    try {
      setCurrentPage(1);
      setRelatedServiceLoader(true)
      const decryptedServiceId = decryptData(serviceId);
      if (decryptedServiceId) {
        const params = {
          page_number: 1,
          per_page: per_page,
          serviceId: decryptedServiceId
        }
        const response = await fetchRelatedServiceData(params);
        const updatedServicesData = {
          services: response?.data?.related_services,
          totalPages: response?.data?.total_pages || 0,
        };
        setRelatedService(updatedServicesData)
        setCurrentPage((prev) => prev + 1)
      }
    }
    catch (err) {

    }
    finally {
      setRelatedServiceLoader(false)
    }
  }

  const getServiceName = (service) => {
    return selectedLanguage === "thai"
      ? service?.service?.service_name_thai &&
        service?.service?.service_name_thai !== "null"
        ? service?.service?.service_name_thai
        : service?.service?.service_name_en
      : service?.service?.service_name_en;
  };

  const getOrganizationName = (serviceData) => {
    return (selectedLanguage === "thai") ? (serviceData?.organization_details?.organization?.name || serviceData?.organization_details?.organization?.name) : (serviceData?.organization_details?.organization?.name)
  }

  const getOrganizationLocation = (serviceData) => {
    return serviceData?.organization_details?.organization?.location
  }

  const navigateOrganizationSection = (service) => {
    const encryptedId = encryptData(service?.organization_details?.organization?.id);
    const formatOrganizationName = organizationUrlFormat(service?.organization_details?.organization?.name);
    navigate(`${HomeRoutes.serviceDescription}/${encryptedId}/${formatOrganizationName}`, {
    });

  }

  const navigateServiceSection = (id, serviceName) => {
    const encryptedId = encryptData(id);
    const formatServiceName = organizationUrlFormat(serviceName);
    navigate(`${HomeRoutes.service}/${encryptedId}/${formatServiceName}`, {
    });
  }

  const getHearIconClassName = (service) => {
    return role === "Customer" && service?.is_favourite
      ? "bi bi-suit-heart-fill"
      : "bi bi-suit-heart";
  };

  const addtocart = async () => {
    try {
      const response = await showCartList();
      let arr = [];
      response?.data?.data?.forEach((obj) => {
        arr.push(obj.service_id);
      });
      setCartItem(arr);
    } catch (err) { }
  };

  const handleAddToCart = async (service_id) => {
    if (role !== "Customer") {
      setShowModal(true);
      setType("cart");
      setCommonId(service_id);
      return;
    }

    try {
      const response = await addCartItem({ service_id });
      const message = response.data.message;

      if (!response.data.status) {
        const newCartCount = (parseInt(cartCount, 10) || 0) + 1;
        localStorage.setItem("cartCount", newCartCount);
        setCartItem((prevCartItems) => [...prevCartItems, service_id]);
      }

      handleSuccessResponse(response, message);
    } catch (err) {
      handleErrorResponse(err);
    }
  };

  const getServiceDesc = (service) => {
    return selectedLanguage === "en" ? service?.service?.description_en : service?.service?.description_thai
  }

  const getServiceSpecialTerm = (service) => {
    return selectedLanguage === "en" ? service?.service?.special_term_en : service?.service?.special_term_thai
  }

  const handleLoadMore = async () => {
    if (relatedServicesLoader) return;
    try {
      setRelatedServiceLoader(true);
      const decryptedServiceId = decryptData(serviceId);
      const params = {
        page_number: currentPage,
        per_page: per_page,
        serviceId: decryptedServiceId,
      };

      const response = await fetchRelatedServiceData(params);
      if (response?.data?.related_services) {
        setRelatedService((prev) => ({
          services: [...prev.services, ...response.data.related_services],
          totalPages: response.data.total_pages || 0,
        }));
        setCurrentPage((prev) => prev + 1);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setRelatedServiceLoader(false);
    }
  };


  const handleLikeClick = async (service) => {
    if (role === 'Customer') {
      try {
        if (!(service?.is_favourite)) {
          const response = await AddOnFavouriteList(service?.service?.id, "service");
          if (response && response?.favoritable_id) {
            setServiceData((prev) => ({
              ...prev,
              favourite_id: response?.favoritable_id,
              is_favourite: true,
            }))
            toast.success(response?.message)
          }
        } else {
          const response = await RemoveFromFavouriteList(service?.favourite_id);
          if (response)
            setServiceData((prev) => ({
              ...prev,
              favourite_id: null,
              is_favourite: false,
            }))
        }
      } catch (error) {
      }
    } else {
      setShowModal(true);
      setType('like');
      setCommonId(service?.service?.id);
      setUserType('vendor');
    }
  };


  return {
    handleAddToCart,
    serviceData,
    getServiceName,
    serviceName,
    previousBreadCrumb,
    getHearIconClassName,
    showModal,
    setShowModal,
    type,
    commonId,
    userType,
    getOrganizationName,
    getOrganizationLocation,
    navigateOrganizationSection,
    getServiceDesc,
    getServiceSpecialTerm,
    loader,
    relatedServices,
    relatedServicesLoader,
    setRelatedServiceLoader,
    currentPage,
    navigateServiceSection,
    handleLoadMore,
    handleLikeClick,
    cartItem
  };
};

export default ServiceSectionLogic;