import {
    LocationPermissionWrapper,
    SignIn,
    User,
    Vendor,
    AboutUsLogic,
    Blog,
    BlogDetails,
    BussinessPolicyLogic,
    CancelPolicyLogic,
    CartItemsLogic,
    ContactUsLogic,
    DisplayServiceLogic,
    FAQLogic,
    ForgotPasswordPage,
    Home,
    MobileResponseId,
    OrderHistoryLogic,
    Payment,
    PaymentSuccess,
    PrivacyPolicyLogic,
    SearchAndDisplayDescLogic,
    SignUp,
    TermConditionLogic,
    VendorShopMap,
    ServiceSection
  } from "../../lazyComponent";
  import { CustomerRoutes, HomeRoutes, AuthRoutes, BusinessRoutes } from "../routes/index";
  
  // Reusable wrapper function for DisplayServiceLogic
  const WrappedDisplayServiceLogic = () => (
    <LocationPermissionWrapper>
      <DisplayServiceLogic />
    </LocationPermissionWrapper>
  );
  
  const appRoutings = [
    { path: CustomerRoutes.successCallback, element: PaymentSuccess },
    { path: "mobile/:id/payment", element: MobileResponseId },
    { path: HomeRoutes.home, element: Home },
    { path: AuthRoutes.signIn, element: SignIn },
    { path: `${AuthRoutes.signUp}/:value`, element: SignUp },
    { path: AuthRoutes.forgotPassword, element: ForgotPasswordPage },
    { path: AuthRoutes.verify, element: ForgotPasswordPage },
    { path: AuthRoutes.createNewPassword, element: ForgotPasswordPage },
    { path: HomeRoutes.about, element: AboutUsLogic },
    { path: HomeRoutes.privacy, element: PrivacyPolicyLogic },
    { path: HomeRoutes.contact, element: ContactUsLogic },
    { path: HomeRoutes.faqs, element: FAQLogic },
    { path: HomeRoutes.businessPolicy, element: BussinessPolicyLogic },
    { path: HomeRoutes.cancelPolicy, element: CancelPolicyLogic },
    { path: HomeRoutes.termCondition, element: TermConditionLogic },
    { path: HomeRoutes.blog, element: Blog },
    { path: `${HomeRoutes.blogDetails}/:encryptedId`, element: BlogDetails },
    { path: `${HomeRoutes.services}/:categoryName/:subCategoryName/:encryptedData`, element: WrappedDisplayServiceLogic },
    { path: `${HomeRoutes.services}${HomeRoutes.search}:searchText`, element: WrappedDisplayServiceLogic },
    { path: `${HomeRoutes.services}/:value`, element: WrappedDisplayServiceLogic },
    { path: `${HomeRoutes.service}/:serviceId/:serviceName`, element: ServiceSection },
    { path: `${CustomerRoutes.payment}/:unique_id`, element: Payment },
    { path: `${CustomerRoutes.cartPayment}/:unique_id`, element: CartItemsLogic },
    { path: `${CustomerRoutes.orderHistory}/:bookingIdsArray`, element: OrderHistoryLogic },
    { path: `${HomeRoutes.serviceDescription}/:organizationId/:shopName`, element: SearchAndDisplayDescLogic },
    { path: HomeRoutes.shopNearMe, element: VendorShopMap },
    { path: CustomerRoutes.users, element: User },
    { path: BusinessRoutes.vendors, element: Vendor }
  ];
  
  export default appRoutings;
  