import { Dropdown, Space,React,Link } from '../../libraries';
const CustomShare = ({
  text,
  items,
  color,
  fontSize,
  placement,
  className,
  shareChange,
  blanck,
}) => {
  const dropdownMenu = (
    <div className="custom-dropdown-menu-share">
      {items &&
        items.map((item, index) => (
          <div key={item.key} className="custom-dropdown-item">
            {item.onClick ? (
              <span onClick={item.onClick}>{item.label}</span>
            ) : (
              <Link
                to={item.path}
                className="navbar-signin-dropdow-toggle"
                target={blanck}
              >
                {item.label}
              </Link>
            )}
            {index < items.length - 1 && (
              <hr className="custom-dropdown-divider" />
            )}
          </div>
        ))}
    </div>
  );

  const renderDropdown =
    items && items.length > 0 ? (
      <Dropdown
        overlay={dropdownMenu}
        placement={placement}
        arrow
        destroyPopupOnHide
      >
        <Space
          className={className}
          style={{ color: color, fontSize: fontSize }}
          onMouseEnter={shareChange}
        >
          {text}
          <div>
            <i className="bi bi-share-fill"></i>
          </div>
        </Space>
      </Dropdown>
    ) : (
      <i className="bi bi-share-fill"></i>
    );

  return <>{renderDropdown}</>;
};
export default CustomShare;