import { apiCall } from "../base";

const getHomeDetail = async () => {
  const response = await apiCall('public/landing_sections', 'GET');
  return response;
};
const creteNewsLetter = async (params) => {
  const response = await apiCall('public/news_letters', 'POST', params)
  return response;
}

const enquiryPlanForTrip = async (params) => {
  const response = await apiCall('public/trips', 'POST', params)
  return response;
}

const fetchHighlyRatedShop = async () => {
  const response = await apiCall('public/highly_rated_vendors', 'GET');
  return response
}

const fetchDealOfTheDay = async () => {
  const response = await apiCall('public/top_10_deals', 'GET');
  return response
}

const fetchHappyHoursData = async (param) => {
  const response = await apiCall(`public/happy_hours?page_number=${param?.page_number}&per_page=${param?.per_page}`, 'GET')
  return response;
}

export { getHomeDetail, fetchHappyHoursData, creteNewsLetter, enquiryPlanForTrip, fetchHighlyRatedShop, fetchDealOfTheDay }