import { AddressJson } from "../../jsonData";
import { Locale, Select, useLocation } from "../../libraries";
import { AuthRoutes, BusinessRoutes } from "../../routes/index";
import './addressSelect.css'
import { useEffect, useState } from "react";
const AddressSelect = ({ section, subDistrictOption, handleCityTypeChange, handleCountryChange, coutryDropDown, filterOption, stateDropdown, addressFormValue, citiesDropdown, formErrors, handleStateChange, handleCityChange, handlePostalCode }) => {
  const { t } = Locale();
  const location = useLocation();
  const [cityErrors, setCityErrors] = useState();
  const isVendorSignUpSection = location.pathname === `${AuthRoutes.signUp}${BusinessRoutes.vendor}` || location.pathname === `${AuthRoutes.signUp}${BusinessRoutes.freelancer}`
  useEffect(() => {
    if (addressFormValue?.city?.name) {
      setCityErrors(null)
    }
    else {
      if (formErrors?.city) {
        setCityErrors(formErrors?.city)
      }
      else {
        setCityErrors(null)
      }
    }

  }, [formErrors, addressFormValue])


  const uniquePostalCodes = [
    ...new Set(AddressJson.map((item) => item.postalCode)),
  ];

  const postalCodeArray = uniquePostalCodes.map((postalCode) => ({
    value: postalCode,
    label: postalCode.toString(),
  }));

  return <>
    {isVendorSignUpSection ? <>
      <div className="row">
        <div className={`${!((addressFormValue?.country?.name) === "Thailand") && 'col-lg-6'}  col-md-12 mt-2`}>
          <label>
            {t("financeField.country")}
            <small className="small-err">*</small>
          </label>
          <Select
            className="finace-ant-select-form w-100"
            placeholder={`${t("header.select")} ${t(
              "field.state"
            )}`}
            optionFilterProp="children"
            onChange={(e) => handleCountryChange(e)}
            options={coutryDropDown}
            value={addressFormValue?.country?.name || undefined}
            filterOption={filterOption}
            showSearch
          />
          <small className="small-err">{formErrors.country}</small>
        </div>
        {!((addressFormValue?.country?.name) === "Thailand") && <>
          <div className="col-lg-6 col-md-12 mt-2">
            <label className="d-block">
              {t("field.state")}
              <small className="small-err">*</small>
            </label>
            <Select
              className="finace-ant-select-form w-100"
              placeholder={`${t("header.select")} ${t(
                "field.state"
              )}`}
              optionFilterProp="children"
              onChange={(e) => handleStateChange(e)}
              options={stateDropdown}
              value={addressFormValue?.state?.name || undefined}
              filterOption={filterOption}
              showSearch
            />
            <small className="input-field-err">{formErrors?.state}</small>
          </div>
        </>}
      </div>
      <div className="row">

        <div className="col-lg-6 col-md-12 mt-2">
          <label>
            {t("field.postalCode")}
            <small className="input-field-err">*</small>
          </label>
          {!((addressFormValue?.country?.name) === "Thailand") ? <>
            <input
              name="postalCode"
              className="countryCode-class"
              placeholder="Enter Postal Code"
              onChange={(e) => handlePostalCode(e.target.value)}
              value={addressFormValue?.postalCode?.value}
            />
          </> :
            <Select
              className="finace-ant-select-form"
              placeholder={`${t("header.select")} ${t(
                "field.postalCode"
              )}`}
              optionFilterProp="children"
              onChange={(value) =>
                handlePostalCode(value)
              }
              options={postalCodeArray}
              value={addressFormValue?.postalCode?.value || undefined}
            />
          }
          <small className="input-field-err">{formErrors?.postalCode}</small>
        </div>
        <div className="col-lg-6 col-md-12 mt-2">
          {!((addressFormValue?.country?.name) === "Thailand") ? <>
            <label className="d-block">
              {t("field.city")}
              <small className="input-field-err">*</small>
            </label>
            <Select
              className="finace-ant-select-form w-100"
              placeholder={`${t("header.select")} ${t(
                "field.city"
              )}`}
              optionFilterProp="children"
              onChange={handleCityChange}
              options={citiesDropdown}
              value={addressFormValue?.city?.name || undefined}
              filterOption={filterOption}
              showSearch
            />
          </> : <>
            <label>
              {t('field.subDistrict')}
              <small className="small-err">*</small>
            </label>
            <Select
              className="finace-ant-select-form"
              placeholder={`${t('header.select')} ${t(
                'field.subDistrict',
              )}`}
              optionFilterProp="children"
              onChange={handleCityChange}
              options={subDistrictOption}
              value={addressFormValue?.city?.name || undefined}
            />
          </>
          }
          <small className="input-field-err">{cityErrors}</small>
        </div>
      </div>

    </> : (section === 'branchSection' && (addressFormValue?.country?.name) !== "Thailand") ? <>
      <div className="row">
        <div className="col-lg-12 col-md-12 mt-2">
          <label className="d-block">
            {t("field.state")}
            <small className="small-err">*</small>
          </label>
          <Select
            className="finace-ant-select-form w-100"
            placeholder={`${t("header.select")} ${t(
              "field.state"
            )}`}
            optionFilterProp="children"
            onChange={(e) => handleStateChange(e)}
            options={stateDropdown}
            value={addressFormValue?.state?.name || undefined}
            filterOption={filterOption}
            showSearch
          />
          <small className="input-field-err">{formErrors?.state}</small>
        </div>
      </div>
      <div className="row mt-2" >
      <div className="col-6">
          <label className="d-block">
            {t("field.city")}
            <small className="input-field-err">*</small>
          </label>
          {((citiesDropdown?.length)) ? <>

            <Select
              className="finace-ant-select-form w-100"
              placeholder={`${t("header.select")} ${t(
                "field.city"
              )}`}
              optionFilterProp="children"
              onChange={handleCityChange}
              options={citiesDropdown}
              value={addressFormValue?.city?.name || undefined}
              filterOption={filterOption}
              showSearch
            />
          </> : <>
            <input
              name="city"
              className="countryCode-class"
              placeholder={`Enter ${t("field.city")}`}
              onChange={handleCityTypeChange}
              value={addressFormValue?.city?.name}
            />
          </>}
          <small className="input-field-err">{cityErrors}</small>
        </div>
        <div className="col-6">
        <label>
            {t("field.postalCode")}
            <small className="input-field-err">*</small>
          </label>
          <input
            name="postalCode"
            className="countryCode-class"
            placeholder="Enter Postal Code"
            onChange={(e) => handlePostalCode(e.target.value)}
            value={addressFormValue?.postalCode?.value}
          />
          <small className="input-field-err">{formErrors?.postalCode}</small>
        </div>
      </div>
    </> : <>
      <div className="row">
        {((addressFormValue?.country?.name) !== "Thailand") && <>
          <div className="col-lg-4 col-md-12 mt-2">
            <label className="d-block">
              {t("field.state")}
              <small className="small-err">*</small>
            </label>
            <Select
              className="finace-ant-select-form w-100"
              placeholder={`${t("header.select")} ${t(
                "field.state"
              )}`}
              optionFilterProp="children"
              onChange={(e) => handleStateChange(e)}
              options={stateDropdown}
              value={addressFormValue?.state?.name || undefined}
              filterOption={filterOption}
              showSearch
            />


            <small className="input-field-err">{formErrors?.state}</small>
          </div>
        </>}

        <div className={`${!((addressFormValue?.country?.name) === "Thailand") ? 'col-lg-4' : 'col-lg-6'} col-md-12 mt-2`}>
          <label>
            {t("field.postalCode")}
            <small className="input-field-err">*</small>
          </label>
          {!((addressFormValue?.country?.name) === "Thailand") ? <>
            <input
              name="postalCode"
              className="countryCode-class"
              placeholder="Enter Postal Code"
              onChange={(e) => handlePostalCode(e.target.value)}
              value={addressFormValue?.postalCode?.value}
            />
          </> :
            <Select
              className="finace-ant-select-form"
              placeholder={`${t("header.select")} ${t(
                "field.postalCode"
              )}`}
              optionFilterProp="children"
              onChange={(value) =>
                handlePostalCode(value)
              }
              options={postalCodeArray}
              value={addressFormValue?.postalCode?.value || undefined}
            />
          }
          <small className="input-field-err">{formErrors?.postalCode}</small>
        </div>

        <div className={`${!((addressFormValue?.country?.name) === "Thailand") ? 'col-lg-4' : 'col-lg-6'} col-md-12 mt-2`}>
          {((addressFormValue?.country?.name) !== "Thailand") ? <>
            <label className="d-block">
              {t("field.city")}
              <small className="input-field-err">*</small>
            </label>
            {((citiesDropdown?.length)) ? <>

              <Select
                className="finace-ant-select-form w-100"
                placeholder={`${t("header.select")} ${t(
                  "field.city"
                )}`}
                optionFilterProp="children"
                onChange={handleCityChange}
                options={citiesDropdown}
                value={addressFormValue?.city?.name || undefined}
                filterOption={filterOption}
                showSearch
              />
            </> : <>
              <input
                name="city"
                className="countryCode-class"
                placeholder={`Enter ${t("field.city")}`}
                onChange={handleCityTypeChange}
                value={addressFormValue?.city?.name}
              />
            </>}

          </> : <>
            <label>
              {t('field.subDistrict')}
              <small className="small-err">*</small>
            </label>
            <Select
              className="finace-ant-select-form"
              placeholder={`${t('header.select')} ${t(
                'field.subDistrict',
              )}`}
              optionFilterProp="children"
              onChange={handleCityChange}
              options={subDistrictOption}
              value={addressFormValue?.city?.name || undefined}
            />
          </>
          }
          <small className="input-field-err">{cityErrors}</small>
        </div>


      </div>
    </>}
  </>
}
export default AddressSelect;