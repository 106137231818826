import { React, useEffect, useState, Locale, dayjs } from '../../libraries';
import { CheckoutCalendarModal } from "../../pages";
import { getCalculationPickAndTotalAmount, handleErrorResponse, serviceIdVendorDetails } from '../../services';
import moment from 'moment-timezone';
const CheckoutCalendarModalLogic = ({ setApiCalculationData, calendarFormValue, setCalendarFormValue, isModalVisible, handleCancel, selectServiceDetails }) => {
  const { t } = Locale();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [showAtLocation, setShowLocation] = useState();
  const [showBranch, setShowBranch] = useState();
  const [options, setOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([])
  const [timeSlots, setTimeSlots] = useState([]);
  const [vendorDetails, setVendorDetails] = useState();
  const [disableConfirmBtn, setDisableConfirmBtn] = useState(false);
  const [vendorOpeningHours, setVendorOpeningHours] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  useEffect(() => {
    getVendorData();
    setShowLocation(calendarFormValue?.[selectServiceDetails?.service_id]?.atServiceLocation)
    setShowBranch(calendarFormValue?.[selectServiceDetails?.service_id]?.branch)
  }, [selectServiceDetails?.service_id]);


  const generateTimeSlots = (date, vender_details) => {
    const dateString = dayjs(date, "DD/MM/YYYY");
    const dayName = dateString.format('dddd');
    const selectedDayName = dayName.toLowerCase();
    const dayDetails = vender_details?.vendor_bussiness_hours?.[selectedDayName];

    if (!dayDetails || dayDetails.shop_open === "false") {
      return [];
    }
    const openTime = dayDetails.open;
    const closeTime = dayDetails.close;
    const timeSlots = [];
    const currentTime = moment(openTime, 'HH:mm');
    const endTime = moment(closeTime, 'HH:mm');
    while (currentTime.isBefore(endTime)) {
      timeSlots.push(currentTime.format('HH:mm'));
      currentTime.add(15, 'minutes');
    }
    const bookingTime = calendarFormValue?.[selectServiceDetails?.service_id]?.booking_time;
    const isAvailableBookingTimeInSlots = (timeSlots?.includes(bookingTime))

    if (!(isAvailableBookingTimeInSlots)) {
      setDisableConfirmBtn(true);
    }
    else {
      setDisableConfirmBtn(false);
    }

    setTimeSlots(timeSlots);
    setSelectedTimeSlot(calendarFormValue?.[selectServiceDetails?.service_id]?.booking_time)
  };

  const getVendorData = async () => {
    try {
      const response = await serviceIdVendorDetails(selectServiceDetails?.service_id);
      setLocationOption(response?.data);
      setBusinessHourseFun(response?.data);
      setBranchOptionsFun(response?.data)
      setVendorDetails(response?.data)
      generateTimeSlots(calendarFormValue?.[selectServiceDetails?.service_id]?.booking_date, response?.data)
    }
    catch (err) {
      handleErrorResponse(err)
    }
  }

  const setLocationOption = (response) => {
    const options = [];
    if ((response?.service_at_customer_location)) {
      options.push({ value: 'at_my_location', label: t('field.atHome') });
    }
    if ((response?.service_at_bussiness_location)) {
      options.push({ value: 'at_shop', label: t('field.atShop') });
    }
    setOptions(options);
  };

  const setBusinessHourseFun = (response) => {
    if (response?.vendor_bussiness_hours) {
      const businessHours = response?.vendor_bussiness_hours;
      setVendorOpeningHours({
        monday: businessHours.monday?.shop_open === 'true',
        tuesday: businessHours.tuesday?.shop_open === 'true',
        wednesday: businessHours.wednesday?.shop_open === 'true',
        thursday: businessHours.thursday?.shop_open === 'true',
        friday: businessHours.friday?.shop_open === 'true',
        saturday: businessHours.saturday?.shop_open === 'true',
        sunday: businessHours.sunday?.shop_open === 'true',
      });
    } else {
      setVendorOpeningHours({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      });
    }
  }

  const setBranchOptionsFun = (response) => {
    let tempOption = [{ value: null, label: selectServiceDetails?.shop_name }];
    const serviceBranchDetails = response?.service?.branch_details
    if (serviceBranchDetails) {
      serviceBranchDetails?.map((obj) => (
        tempOption.push({ value: obj?.id, label: obj?.name })))
    }
    setBranchOptions(tempOption);
  }

  const disabledDate = (current) => {
    if (current < moment().startOf('day')) {
      return true;
    }
    const dayName = current.format('dddd').toLowerCase();
    return !vendorOpeningHours[dayName];
  };

  const handleSubmit = async (event) => {
    const booking_date = selectedDate?.format('DD-MM-YYYY') || calendarFormValue?.[selectServiceDetails?.service_id]?.booking_date;
    const booking_time = selectedTimeSlot;
    setCalendarFormValue((prev) => ({
      ...prev,
      [selectServiceDetails?.service_id]: {
        ...prev[selectServiceDetails?.service_id],
        atServiceLocation: showAtLocation,
      }
    }));

    await setCalendarFormValue((prev) => ({
      ...prev,
      [selectServiceDetails?.service_id]: {
        ...prev[selectServiceDetails?.service_id],
        booking_date,
        booking_time,
      }
    }));

    await getCalculationData(booking_date, booking_time)
    await handleCancel()
  }

  const getCalculationData = async (booking_date, booking_time) => {
    try {
      const booking_service_details = Object.keys(calendarFormValue).map(service_id => {
        if (service_id === selectServiceDetails?.service_id) {
          return {
            service_id: selectServiceDetails?.service_id,
            booking_date: booking_date,
            booking_time: booking_time,
            branch_id: calendarFormValue[service_id]?.branch?.branch_id
          };
        } else {
          return {
            service_id: parseInt(service_id),
            booking_date: calendarFormValue[service_id]?.booking_date,
            booking_time: calendarFormValue[service_id]?.booking_time,
            branch_id: calendarFormValue[service_id]?.branch?.branch_id
          };
        }
      });

      let param = { booking_service_details };
      let response = await getCalculationPickAndTotalAmount(param);
      setApiCalculationData(response?.data?.booking);
      setCalendarFormValue((prev) => ({
        ...prev,
        [selectServiceDetails?.service_id]: {
          ...prev[selectServiceDetails?.service_id],
          atServiceLocation: showAtLocation,
          branch: {
            branch_id: showBranch?.branch_id,
            branch_name: showBranch?.branch_name,
          },
          booking_date,
          booking_time,
        }
      }));
      handleCancel();
    }
    catch (err) {
      handleErrorResponse(err)
    }
  }

  const handleLocationChange = (id, value) => {
    setShowLocation(value)
  }

  const handleBranchChange = (id, value) => {
    setShowBranch({
      branch_id: id,
      branch_name: value?.label
    })
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
    generateTimeSlots(date, vendorDetails)
  };

  const handleTimeSlotClick = (slot) => {
    setDisableConfirmBtn(false)
    setSelectedTimeSlot(slot)
  }


  return <>
    <CheckoutCalendarModal
      disableConfirmBtn={disableConfirmBtn}
      showBranch={showBranch}
      showAtLocation={showAtLocation}
      isModalVisible={isModalVisible}
      handleCancel={handleCancel}
      selectServiceDetails={selectServiceDetails}
      handleOk={handleSubmit}
      handleLocationChange={handleLocationChange}
      disabledDate={disabledDate}
      uniqueId={`date-popup-${selectServiceDetails?.service_id}-${performance.now()}`}
      options={options}
      selectedDate={selectedDate}
      selectedTimeSlot={selectedTimeSlot}
      onDateChange={handleDateChange}
      calendarFormValue={calendarFormValue}
      handleBranchChange={handleBranchChange}
      branchOptions={branchOptions}
      handleTimeSlotClick={handleTimeSlotClick}
      timeSlots={timeSlots}
    />
  </>
}

export default CheckoutCalendarModalLogic;