export const logApiTime = (method, path, duration, isError = false) => {
    const logKey = `${method}-${path}`;
    const logData = {
      key: logKey,
      timestamp: new Date().toISOString(),
      method,
      path,
      duration: `${duration} ms`,
      status: isError ? 'FAILED' : 'SUCCESS',
    };
  
    // Get existing logs
    let logs = JSON.parse(localStorage.getItem('apiLogs')) || [];
  
    // Check for duplicate entry
    const isDuplicate = logs.some(log => log.key === logKey);
    if (!isDuplicate) {
      logs.push(logData);
      localStorage.setItem('apiLogs', JSON.stringify(logs)); // Store in localStorage
    }
  };