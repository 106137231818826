import './Marquee.css';
import { React } from '../../../libraries';

const MarqueeComponent = ({ sale }) => {
  return (
    <div className="marquee">
      <span className="font-weight-bold">{sale}</span>
    </div>
  );
};

export default MarqueeComponent;