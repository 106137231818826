import { getCode } from 'country-list';
import { CountryCodeJson } from '../jsonData';

const phoneCodeMap = new Map();
const countryNameMap = new Map();

CountryCodeJson.forEach(country => {
    phoneCodeMap.set(country.dial_code, country.code.toLowerCase());
    countryNameMap.set(country.name.toLowerCase(), country.code.toLowerCase());
});

export const dynamicTimeZone = () => {
    return "Asia/Bangkok"
}

export const getPhoneCode = (phone_code) => {
    if (phone_code) {
        const countryCode = phoneCodeMap.get(phone_code);
        if (countryCode) {
            return countryCode;
        }
    }

    const currentCountry = sessionStorage.getItem('country');
    if ((currentCountry) && (currentCountry !== 'undefined')) {
        return (getCode(currentCountry))?.toLowerCase();;
    }

    return 'th';
};


export const getDefaultCountryCode = () => {
    const isIndiaCountry = sessionStorage.getItem('country') === 'India';
    if (isIndiaCountry) {
        return '+91'
    }
    return '+66'
}