import { React, useEffect, useLocation, useState } from '../../../libraries/index';
import { AccountDetails } from '../../pages/index';
import { handleErrorResponse, handleSuccessResponse, delete_image, vendorOrgnizationInformation } from '../../../services/index';
import { CancelDeletionAction } from '../../../lazyComponent';

const VendorAccountDetails = ({ deletionRequest, setDeletionRequest }) => {
  const location = useLocation();
  const [vendorAccoutnDetails, setVendorAccoutnDetails] = useState();
  const deletionRequestStatus = localStorage.getItem('deletionRequestStatus') === 'requested'

  const initialValues = {
    venue_name: '',
    countrycode: '',
    venueThaiName: '',
    venueThaiDescription: '',
    venueEnglishDescription: '',
    address: '',
    country: '',
    state: '',
    city: '',
    venuPhoneNumber: '',
    website: '',
    shopImages: [],
    shopImagesShow: [],
    shopTempImages: [],
  };
  const addressInitialValues = {
    country: { id: '', name: '', code: '' },
    state: { id: '', name: '', code: '' },
    city: { name: '' },
    postalCode: { value: '' }
  }
  const [addressFormValue, setAddressFormValue] = useState(addressInitialValues)
  const [formValue, setFormValue] = useState(initialValues);
  const [isLoader, setIsLoader] = useState(false);
  const isSection = localStorage.getItem('isSection');
  const registeredCountry = sessionStorage.getItem('country')

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
    ],
  };
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'indent',
    'link',
  ];

  useEffect(() => {
    if (isSection !== 'isBusinessAccount') {
      localStorage.setItem('isSection', 'isBusinessAccount');
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoader(true);
        const response = await vendorOrgnizationInformation();
        setVendorAccoutnDetails(response.data);
      } catch (error) {
        handleErrorResponse(error);
      } finally {
        setIsLoader(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (vendorAccoutnDetails && vendorAccoutnDetails.organization) {
      let phone_number =
        vendorAccoutnDetails &&
        vendorAccoutnDetails.organization.phone &&
        vendorAccoutnDetails.organization.phone.split(' ').length > 1 &&
        vendorAccoutnDetails.organization.phone.split(' ')[1].toString();

      let tempCountryCode =
        vendorAccoutnDetails &&
        vendorAccoutnDetails.organization.phone &&
        vendorAccoutnDetails.organization.phone.split(' ').length > 1 &&
        vendorAccoutnDetails.organization.phone.split(' ')[0].toString();
      let tempImageArray;

      tempImageArray = Object.values(
        vendorAccoutnDetails.organization_image,
      ).map((imageObject) => imageObject.url);

      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        email: vendorAccoutnDetails.organization.email,
        bussiness_hours: vendorAccoutnDetails.organization.bussiness_hours,
        venue_name: vendorAccoutnDetails.organization.name,
        countrycode: tempCountryCode,
        venueThaiName:
          vendorAccoutnDetails.organization?.name_thai === 'null'
            ? ''
            : vendorAccoutnDetails.organization?.name_thai,
        address: vendorAccoutnDetails.organization.location,
        venuPhoneNumber: phone_number,
        website:
          vendorAccoutnDetails.organization.website === 'null'
            ? ''
            : vendorAccoutnDetails.organization.website,
        shopImages: tempImageArray,
        shopTempImages: vendorAccoutnDetails.organization_image,
        serviceMyLocation:
          vendorAccoutnDetails.organization.service_at_bussiness_location,
        serviceCustomerLocation:
          vendorAccoutnDetails.organization.service_at_customer_location,
        breakTime: vendorAccoutnDetails.organization.break_time,
        cancellationPolicy:
          vendorAccoutnDetails.organization.cancellation_policy,
        cash_payment: vendorAccoutnDetails.organization.cash_payment,
        pay_online: vendorAccoutnDetails.organization.online_payment,
        venueEnglishDescription:
          vendorAccoutnDetails?.organization?.description,
        venueThaiDescription:
          vendorAccoutnDetails?.organization?.description_thai,
      }));
      
      setAddressFormValue((prev) => ({
        ...prev,
        country: { id: '', name:registeredCountry , code: '' },
        state: { id: '', name: vendorAccoutnDetails.organization.state, code: '' },
        city: { name: vendorAccoutnDetails.organization.city },
        postalCode: { value: vendorAccoutnDetails.organization.postcode }
      }))
    }
  }, [vendorAccoutnDetails?.organization]);

  const deleteImage = (deleteImageIndex) => {
    setFormValue((prevState) => {
      const deleteImageUrl = prevState.shopImages[deleteImageIndex];
      const updatedShopImages = prevState.shopImages.filter(
        (image, index) => index !== deleteImageIndex,
      );
      let deleteImageId;
      for (const key in formValue.shopTempImages) {
        const imageObject = formValue.shopTempImages[key];
        if (imageObject.url === deleteImageUrl) {
          deleteImageId = imageObject.id;
          delete_image(deleteImageId)
            .then((response) => {
              handleSuccessResponse(response, response.data.message);
            })
            .catch((error) => {
              handleErrorResponse(error);
            });
        }
      }
      return {
        ...prevState,
        shopImages: updatedShopImages,
      };
    });
  };

  const handleDescriptionChange = (name, value) => {
    setFormValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePhoneCountryCodeChange = (countryCode, countryData) => {
    setFormValue((prevValues) => ({
      ...prevValues,
      countrycode: `+${countryCode?.dialCode}`,
    }));
  }

  return (
    <>
      {deletionRequestStatus ? (
        <>
          <CancelDeletionAction
            setDeletionRequest={setDeletionRequest}
            deletionRequest={deletionRequest}
          />
        </>
      ) : (
        <AccountDetails
        modules={modules}
        formats={formats}
        handleDescriptionChange={handleDescriptionChange}
        isLoader={isLoader}
        deleteImage={deleteImage}
        formValue={formValue}
        setFormValue={setFormValue}
        addressFormValue={addressFormValue}
        setAddressFormValue={setAddressFormValue}
        deletionRequest={deletionRequest}
        handlePhoneCountryCodeChange={handlePhoneCountryCodeChange}
        />
      )}
    </>
  );
};
export default VendorAccountDetails;
