export const returnObj = (values, slotArray) => {

  let data = {
    category_id: values?.categoryId,
    sub_category_id: values?.subCategoryId,
    duration: values?.duration,
    service_name_en: values?.serviceNameEnglish,
    service_name_thai: values?.serviceNameThai,
    ps_start_date: values?.psStartDate,
    ps_end_date: values?.psEndDate,
    price: values?.price,
    special_price: values?.specialPrice,
    description_en: values?.descriptionEnglish,
    description_thai: values?.descriptionThailand,
    cancel_day: values?.cancelDay,
    reschedule_day: values?.reschedule,
    special_term_en: values?.specialTermEnglish,
    special_term_thai: values?.specialTermThailand,
    is_featured_service: values.vouturs?.includes('Featured Service'),
    is_voucher: values.vouturs?.includes('Voucher'),
    is_installment_enabled: values.vouturs?.includes('Installment'),
    is_enabled: true,
    image: (values?.image) ? values?.image : "",
    display_position: values?.displayPosition,
    available_days: {
      monday: values.available?.includes('monday'),
      tuesday: values.available?.includes('tuesday'),
      wednesday: values.available?.includes('wednesday'),
      thrusday: values.available?.includes('thrusday'),
      friday: values.available?.includes('friday'),
      saturday: values.available?.includes('saturday'),
      sunday: values.available?.includes('sunday'),
    },
    selected_team_member: values?.avialableTeamMembers,
  };

  let formData = new FormData();
  for (let key in data) {
    if (data[key] !== undefined && data[key] !== null) {
      if (key === 'image' && data[key] !== "" && (data[key] instanceof File)) {
        formData.append(key, data[key]);
      } else if (key !== 'image') {
        formData.append(key, data[key]);
      }
    }
  }
  
  formData.append("discount_slot[slots]", JSON.stringify(slotArray));

  return formData;
};
