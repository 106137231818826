import { React, Button, message, CopyOutlined } from '../../libraries/index';

const copyToClipboard = (text) => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);

  message.success('Copied to clipboard');
};

const CopytoClipboard = ({ referralCode }) => (
  <div className="copy-referral-code">
    <div className="mt-3">
      <p>
        <span className="referral-code">
          {referralCode} &nbsp;
          <Button
            icon={<CopyOutlined />}
            onClick={() => copyToClipboard(referralCode)}
          />
        </span>
      </p>
    </div>
  </div>
);

export default CopytoClipboard;