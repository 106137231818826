import './currencyDisplay.css';
const CurrencyDisplay = ({ value, subPrice, formatedValue, component }) => {
  const currentLocation = sessionStorage.getItem('country');
  const currencyIcon = currentLocation === 'India' ? '₹' : '฿';

  function convertToCurrencyDisplay(subPriceDisplay) {
    const [symbol, number] = subPriceDisplay.split(" ");
    const formattedNumber = Number(number.replace(/,/g, "")).toLocaleString("en-IN", { minimumFractionDigits: 2 });
    return `${symbol} ${formattedNumber}`;
  }

  if (formatedValue) {
    const currencyDisplay = `${currencyIcon} ${formatedValue}.00`;

    return (
      <>
        {' '}
        <div className="hl05eU">
          <div className="Nx9bqj CxhGGd">{currencyDisplay}</div>
        </div>
      </>
    );
  }
  else if (value) {
    const formattedValue = value?.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const currencyDisplay = `${currencyIcon} ${formattedValue}`;
    const subPriceDisplay = `${currencyIcon} ${subPrice}.00`;

    return (
      <>
        <div className="hl05eU d-flex align-items-center gap-2">

          {component ? <>
            {subPrice && currencyDisplay !== convertToCurrencyDisplay(subPriceDisplay) && <div className="yRaY8j A6+E6v">{subPriceDisplay}</div>}
          </> : <>
            {subPrice && <div className="yRaY8j A6+E6v">{(currencyDisplay !== subPriceDisplay) && subPriceDisplay}</div>}
          </>}
          <div
            className={`Nx9bqj CxhGGd ${subPrice && currencyDisplay && 'fw-bold currency-display-d-price'}`}
          >
            {currencyDisplay}
          </div>
        </div>
      </>
    );
  } else {
    const currencyDisplay = `${currencyIcon} 00.00`;
    return (
      <>
        <div className="hl05eU">
          <div className="Nx9bqj CxhGGd">{currencyDisplay}</div>
          {subPrice && (
            <div className="yRaY8j A6+E6v currency-display-sub-price-second">
              ${currencyIcon}{subPrice}
            </div>
          )}
        </div>
      </>
    );
  }
};
export default CurrencyDisplay;