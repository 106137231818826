import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React, { useEffect, Suspense, useMemo } from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AOS from 'aos';
import { fetchUserFooterInformation } from './redux/index.js';
import { useUserRoleAndSection, MetaTag, fetchIpBasedLocation } from './customHooks/index.js';
import { PaymentDone, PaymentFailed, PageNotFound, CustomSpinner } from './pages/index.js';
import { AppRouting } from './routes/index.js';

const App = () => {
  const dispatch = useDispatch();
  const { role, isSection } = useUserRoleAndSection();
  const vendorRegisteredLocation = localStorage.getItem('registeredCountry');

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'default-theme';
    document.body.className = savedTheme;

    if (!role || (role === 'Customer' && !isSection)) {
      dispatch(fetchUserFooterInformation());
    }

    if (vendorRegisteredLocation && vendorRegisteredLocation !== 'null') {
      sessionStorage.setItem('country', vendorRegisteredLocation);
    } else if (!sessionStorage.getItem('country')) {
      fetchIpBasedLocation();
    }
  }, [dispatch, role, isSection, vendorRegisteredLocation]);

  const memoizedRoutes = useMemo(() => (
    <Routes>
      {AppRouting.map(({ path, element, exact = false }) => (
        <Route
          key={path}
          path={path}
          exact={exact}
          element={
            <Suspense fallback={<CustomSpinner />}>
              {React.createElement(element)}
            </Suspense>
          }
        />
      ))}
      <Route path='/payment-done' element={<PaymentDone />} />
      <Route path='/payment-fail' element={<PaymentFailed />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  ), [AppRouting]);

  return (
    <>
      <MetaTag />
      <Router>
        {memoizedRoutes}
      </Router>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default App;