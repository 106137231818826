import { React, useEffect, useState, useRef, Locale } from "../../libraries";
import MobileCategorySection from "./mobileCategorySection";
import WebCategorySection from "./webCategorySection";

export const CustomDropDownCategories = ({
  visibleSlide1,
  closeMenuCategory,
  onBestSellerEnter,
  menuRef,
  overlayRef,
  subCategoryClickVisible,
  handleCategoryClickFun,
  items,
  onCategoryClick,
  subCategoriesDatas,
  onSubCategoryClick,
  handleMouseLeave,

}) => {
  const { t } = Locale();
  const role = localStorage.getItem("role");
  const [isBackBtn, setIsBackBtn] = useState(false);
  const [title, setTitle] = useState(t("ServiceField.category"));
  const [activeCategoryId, setActiveCategoryId] = useState(null); 
  const [isWebCategorySection, setIsWebCategorySection] = useState(window.innerWidth >= 992)
  const submenu1Ref = useRef({});
  const mobileMenuHeadRef = useRef(null);

  useEffect(() => {
    submenu1Ref.current = {};
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setIsWebCategorySection(window.innerWidth >= 991);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let lastScrollTop = 0;
    const delta = 5;
    const headerElement = document.querySelector("header");
    const navbarHeight = headerElement.offsetHeight;

    const handleScroll = () => {
      const st = window.scrollY;
      if (Math.abs(lastScrollTop - st) <= delta) return;

      if (st > lastScrollTop && st > navbarHeight) {
        headerElement.classList.remove("nav-down");
        headerElement.classList.add("nav-up");
      } else {
        if (st + window.innerHeight < document.documentElement.scrollHeight) {
          headerElement.classList.remove("nav-up");
          headerElement.classList.add("nav-down");
        }
      }
      lastScrollTop = st;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  const hideSubMenu = () => {
    setTitle(t("ServiceField.category")); 
    setActiveCategoryId(null); 
    setIsBackBtn(false); 
  };


  return (
    <header className="header2">
      <div>
        {(role === "Customer" || !role) && (
          <div className="row d-flex justify-content-center">
            <div className="header-item d-flex justify-content-center">
              <div className="menu-overlay" ref={overlayRef}></div>
              <nav className="menu CustomDropDownCategories-nav" ref={menuRef}>
                <div className="mobile-menu-head" ref={mobileMenuHeadRef}>
                  <div
                    className={`go-back ${!isBackBtn && "disable-go-back"}`}
                    onClick={hideSubMenu}
                  >
                    <i className="bi bi-arrow-left"></i>
                  </div>
                  <div className="current-menu-title colorWhite">{title}</div>
                  <div
                    className="mobile-menu-close"
                    id="closeBtn-category-module"
                    onClick={() => closeMenuCategory()}
                  >
                    &times;
                  </div>
                </div>
                {isWebCategorySection ? <>
                  <WebCategorySection
                    subCategoryClickVisible={subCategoryClickVisible}
                    handleCategoryClickFun={handleCategoryClickFun}
                    items={items}
                    onCategoryClick={onCategoryClick}
                    subCategoriesDatas={subCategoriesDatas}
                    onSubCategoryClick={onSubCategoryClick}
                    handleMouseLeave={handleMouseLeave}
                    visibleSlide1={visibleSlide1}
                    onBestSellerEnter={onBestSellerEnter}
                  />
                </> : <>
                  <MobileCategorySection
                    setIsBackBtn={setIsBackBtn}
                    setActiveCategoryId={setActiveCategoryId}
                    activeCategoryId={activeCategoryId}
                    setTitle={setTitle}
                    subCategoryClickVisible={subCategoryClickVisible}
                    handleCategoryClickFun={handleCategoryClickFun}
                    items={items}
                    onCategoryClick={onCategoryClick}
                    subCategoriesDatas={subCategoriesDatas}
                    onSubCategoryClick={onSubCategoryClick}
                    handleMouseLeave={handleMouseLeave}
                  />
                </>}
              </nav>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};