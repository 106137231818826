import { React, useNavigate } from '../../libraries';

export const CustomButton = ({
  closeButtonObject,
  name,
  onClick,
  formValue,
  setErr,
  formType,
  className,
  navigateUrl,
  data_bs_toggle,
  data_bs_target,
  data_bs_dismiss,
  disabled,
  setButtonDisabled,
  t,
  addressFormValue
}) => {
  const navigate = useNavigate();
  return (
    <>
      {formType === 'uploadfile-modal' || formType === 'cancel-button' ? (
        <>
          <button
            onClick={(e) => onClick(e, closeButtonObject, t)}
            className={`btn ${className}`}
          >
            {name}
          </button>
        </>
      ) : (
        <>
          {onClick ? (
            <>
              <button
                className={`btn ${className}`}
                onClick={(e) =>
                  onClick(
                    e,
                    formValue,
                    setErr,
                    formType,
                    navigateUrl,
                    navigate,
                    closeButtonObject,
                    setButtonDisabled,
                    t,
                    addressFormValue
                  )
                }
                disabled={disabled}
              >
                {name}
              </button>
            </>
          ) : (
            <>
              {data_bs_target ? (
                <>
                  <button
                    data-bs-toggle={data_bs_toggle}
                    data-bs-target={data_bs_target}
                    className={`btn ${className}`}
                  >
                    {name}
                  </button>
                </>
              ) : (
                <>
                  {data_bs_dismiss && (
                    <button
                      data-bs-dismiss={data_bs_dismiss}
                      className={`btn ${className}`}
                    >
                      {name}
                    </button>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};