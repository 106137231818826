import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import i18next from 'i18next';
import { I18nContextProvider } from 'react-admin';

const root = ReactDOM.createRoot(document.getElementById('root'));
const REACT_APP_GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID;
root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_ID}>
      <I18nContextProvider i18n={i18next}>
        <App />
      </I18nContextProvider>
    </GoogleOAuthProvider>
  </Provider>,
);

