import { Locale } from "../../libraries";
import { Modal, Button } from 'react-bootstrap';
const PermissionDenied = ({ showModal, handleClose }) => {
  const { t } = Locale();
  return <>
    <Modal
      show={showModal}
      onHide={handleClose}
      dialogClassName="custom-modal customModal"
    >
      <Modal.Header
        closeButton
        className="headerLine"
        onClick={handleClose}
      />
      <Modal.Body>
        <div className="text-center">
          <div className="delete-pop-up-message">
            {t('paymentSection.locationDennied')}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center footerLine">
        <Button
          className="btn btn-light cancelbutton"
          onClick={handleClose}
        >
          {t('button.ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  </>
}

export default PermissionDenied;