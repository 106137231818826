import {  AddNewService, BookingsList, Branch, DashBoardLogic,VendorAccountDeletionRequest, Finances, MyCalender, OpeningHoursPolicies, Payout, PayoutSummary, ReviewLogic, ServicesList, VendorAccountDeletion, VendorAccountDetails, VendorProfile, VendorTeamListLogic } from "../../lazyComponent";
import { BusinessRoutes, CustomerRoutes } from "../routes";
const businessRoutings = [
    { path: `${BusinessRoutes.dashboard}`, Component: DashBoardLogic },
    { path: `${BusinessRoutes.profile}`, Component: VendorProfile },
    { path: `${CustomerRoutes.accountDeletion}`,Component:VendorAccountDeletion },
    { path: `${CustomerRoutes.deleteRequest}`, Component: VendorAccountDeletionRequest },
    { path: `${BusinessRoutes.accountDetails}`, Component: VendorAccountDetails },
    { path: `${BusinessRoutes.openinghoursandpolicies}`, Component: OpeningHoursPolicies },
    { path: `${BusinessRoutes.finance}`, Component: Finances },
    { path: `${BusinessRoutes.payout}`, Component: Payout },
    { path: `${BusinessRoutes.payoutSummary}/:encryptedData`, Component: PayoutSummary },
    { path: `${BusinessRoutes.bussinessServices}`, Component: ServicesList },
    { path: `${BusinessRoutes.addService}`, Component: AddNewService },
    { path: `${BusinessRoutes.buisinesseditService}`, Component: AddNewService },
    { path: `${BusinessRoutes.teamMembers}`, Component: VendorTeamListLogic },
    { path: `${BusinessRoutes.bussinessbookings}`, Component: BookingsList },
    { path: `${BusinessRoutes.calender}`, Component: MyCalender },
    { path: `${BusinessRoutes.branches}`, Component: Branch },
    { path: `${BusinessRoutes.businessReview}`, Component: ReviewLogic },
];

export default businessRoutings;