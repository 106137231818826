import { React, Locale, useLocation, useEffect, useState, message } from '../../../libraries';
import { useDebouncedValue } from '../../../jsonData';
import { branchDeleteImage, createBranch, filterBranch, showBranch, updateBranch, handleErrorResponse, handleSuccessResponse } from '../../../services';
import { GetOrganizationStatus } from '../index';
import { checkFileSize } from '../../../common/uploadFileModal/fileSizeLogic';
import { Branches } from '../../pages';
const Branch = () => {
  const location = useLocation();
  const { t } = Locale();
  const registeredCountry = sessionStorage.getItem('country')
  const { isOrganizationStatus } = GetOrganizationStatus();
  const initialValuesFilter = {
    branch_name: '',
    is_enabled: '',
    address: '',
    district: '',
    postal_code: '',
    state: '',
    search: '',
    service:'',
    page_number: '1',
    per_page: '15',
  };
  const initialValues = {
    branchName: '',
    availableForService: [],
    subDistrict: '',
    country: '',
    state: '',
    city: '',
    address: '',
    shopImages: [],
    selectedImageArray: [],
    is_enabled: true,
  };
  const addressInitialValues = {
    country: { id: '', name: registeredCountry, code: '' },
    state: { id: '', name: '', code: '' },
    city: { name: '' },
    postalCode: { value: '' }
  }
  const [addressFormValue, setAddressFormValue] = useState(addressInitialValues)
  const [filterFormValue, setFilterFormValue] = useState(initialValuesFilter);
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageFile, setSelectedImageFile] = useState([]);
  const [responseEditNew, setResponseEditNew] = useState();
  const [totalPage, setTotlePage] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState(initialValues);
  const [loader, setLoader] = useState(false);
  const debouncedSearchTerm = useDebouncedValue(filterFormValue, 500);
  const [isFilterCall, setIsFilterCall] = useState(false);
  const [isShowEmptyPage, setIsShowEmptyPage] = useState(false);
  const isSection = localStorage.getItem('isSection');
  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    if (isSection !== 'isBusinessBranch') {
      localStorage.setItem('isSection', 'isBusinessBranch');
    }
  }, [location.pathname]);

  useEffect(() => {
    setFormValues((prev) => ({
      ...prev,
      selectedImageArray: selectedImageFile,
    }));
  }, [selectedImages]);

  const fetchData = async () => {
    try {
      setLoader(true);
      if (isFilterCall) {
        const cleanupFunction = await filterBranch(filterFormValue);
        setData(cleanupFunction.data.branches);
        if (
          cleanupFunction?.data?.branches?.length < 0 ||
          !cleanupFunction?.data?.branches ||
          cleanupFunction?.data?.status_code === 204
        )
          setIsShowEmptyPage('emptyBranchFilter');
        else setIsShowEmptyPage(false);
        setTotlePage(
          cleanupFunction.data.total_pages
            ? cleanupFunction.data.total_pages * 15
            : 15,
        );
        if (
          cleanupFunction.data.total_pages === 1 &&
          filterFormValue.page_number > 1
        ) {
          setFilterFormValue((prevValue) => ({
            ...prevValue,
            page_number: '1',
            per_page: '15',
          }));
        }
        if (typeof cleanupFunction === 'function') {
          cleanupFunction();
        }
      } else {
        let response = await showBranch();
        setData(response.data.branches);
        if (
          response?.data?.branches?.length < 0 ||
          !response?.data.branches ||
          response?.data?.status_code === 204
        ) {
          setIsShowEmptyPage('emptyBranch');
        } else {
          setIsShowEmptyPage(false);
          setIsFilterCall(true);
        }
      }
    } catch (err) {
      setIsShowEmptyPage(true);
      handleErrorResponse(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (isOrganizationStatus === 'verified') {
      fetchData();
    }
  }, [debouncedSearchTerm, responseEditNew, isOrganizationStatus]);

  const getValidationHandleChange = (name, value) => {
    let error = { ...formErrors };
    if (error.hasOwnProperty(name)) {
      delete error[name];
    }

    switch (name) {
      case 'branchName':
        if (!value) {
          error.branchName = `${t('field.pleaseEnter')} ${t('vendor.branchName')}`;
        } else {
          error.branchName = ''; 
        }
        break;

      case 'postalCode':
        if (!value) {
          error.postalCode = `${t('field.pleaseSelect')} ${t('field.postalCode')}`;
        } else {
          error.postalCode = ''; 
        }
        break;

      case 'subDistrict':
        if (!value) {
          error.subDistrict = `${t('field.pleaseSelect')} ${t('field.subDistrict')}`;
        } else {
          error.subDistrict = ''; 
        }
        break;

      case 'address':
        if (!value) {
          error.address = `${t('field.pleaseEnter')} ${t('field.address')}`;
        } else {
          error.address = ''; 
        }
        break;

      default:
        break;
    }
    return error;
  };

  const handleChange = (name, value) => {
    const errorLogs = getValidationHandleChange(name, value);
    setFormErrors(errorLogs);
    setFormValues({ ...formValues, [name]: value });
  };

  const handleChangeFilter = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilterFormValue((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handlePaginationChange = async (page, pageSize) => {
    setFilterFormValue((prevValue) => ({
      ...prevValue,
      page_number: page,
      per_page: pageSize,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e;
    if (file?.type?.includes('image') && checkFileSize(file, t)) {
      if (selectedImageFile.length >= 5 || selectedImages.length >= 5) {
        message.error(`${t("validation.max")} !!!`)
        setSelectedImageFile("");
        setSelectedImage(selectedImages[selectedImages.length - 1]);
        return;
      }
    }
    else
      return;
    const updatedImageArray = [...selectedImageFile, file];
    setSelectedImageFile(updatedImageArray);
    const reader = new FileReader();
    reader.onload = (event) => {
      const imageUrl = event.target.result;
      setSelectedImage(imageUrl);
      setSelectedImages([...selectedImages, imageUrl]);
    };
    reader.readAsDataURL(file);
  };


  useEffect(() => { }, selectedImageFile.length, selectedImages.length);

  const handleDeselectImage = async (index, images, setImages, updatedData) => {
    const updatedImages = [...selectedImages];
    const updateImgeArray = [...formValues.selectedImageArray];
    if (Object.keys(images).length > 0) {
      const updateImagesId = [...images];
      let url = selectedImages[index];
      let imgId;
      for (const item of images) {
        const id = Object.keys(item)[0];
        const imgurl = Object.values(item)[0];
        if (imgurl === url) {
          imgId = id;
          break;
        }
      }
      if (imgId) {
        let params = {
          branchId: updatedData.branch.id,
          imageId: imgId,
        };
        try {
          let response = await branchDeleteImage(params);
          updateImagesId.splice(index, 1);
          setImages(updateImagesId);
          updatedImages.splice(index, 1);
          updateImgeArray.splice(index, 1);
          setFilterFormValue((prevValue) => ({
            ...prevValue,
          }));
          setSelectedImages(updatedImages);
          setSelectedImageFile(updateImgeArray);
          setSelectedImage(
            updatedImages.length > 0
              ? updatedImages[updatedImages.length - 1]
              : null,
          );
          handleSuccessResponse(response, response.data.message);
        } catch (err) {
          handleErrorResponse(err);
        }
      } else {
        updatedImages.splice(index, 1);
        updateImgeArray.splice(index, 1);
        setSelectedImages(updatedImages);
        setSelectedImageFile(updateImgeArray);
        setSelectedImage(
          updatedImages.length > 0
            ? updatedImages[updatedImages.length - 1]
            : null,
        );
      }
    } else {
      updatedImages.splice(index, 1);
      updateImgeArray.splice(index, 1);
      setSelectedImages(updatedImages);
      setSelectedImageFile(updateImgeArray);
      setSelectedImage(
        updatedImages.length > 0
          ? updatedImages[updatedImages.length - 1]
          : null,
      );
    }
  };

  const handleStatusChange = async (branch) => {
    let formData = appendData(branch.branch);
    let params = {
      id: branch.branch.id,
      data: formData,
    };
    try {
      await updateBranch(params);
      setFilterFormValue((prevValue) => ({
        ...prevValue,
      }));
    } catch (err) {
      if (err.response.data.status_code === 404) {
        handleErrorResponse(err);
      }
    }
  };

  const handleSubmit = async (
    e,
    closeButtonElement,
    branch,
    closeEditBookingModal,
  ) => {
    e.preventDefault();
    formValues.availableForService = selectedItems
    const errors = await validate(formValues);
    setFormErrors(errors);
    if (Object.values(errors).every((value) => value === '')) {
      setLoader(true);
      formValues.availableForService = selectedItems
      let formData = appendData1(formValues);
      if (!branch) {
        try {
          let response;
          formValues.availableForService = selectedItems;
          response = await createBranch(formData);
          handleSuccessResponse(response, response.data.message);
          setResponseEditNew(response);
          closeEditBookingModal();
          handleClearPreviousValue()

        } catch (err) {
          handleErrorResponse(err);
        } finally {
          setLoader(false);
        }
      } else {
        let params = {
          id: branch.branch.id,
          data: formData,
        };
        try {
          formValues.availableForService = selectedItems;
          let response = await updateBranch(params);
          setSelectedImageFile([]);
          setFilterFormValue((prevValue) => ({
            ...prevValue,
          }));
          handleSuccessResponse(response, response.data.message);
          const closeButtonObject = {
            element: closeButtonElement,
            click: function () {
              setFormValues({
                branchName: '',
                subDistrict: '',
                postalCode: '',
                country: '',
                state: '',
                city: '',
                address: '',
                shopImages: [],
                selectedImageArray: [],
                is_enabled: true,
              });
              setSelectedItems([])
              closeButtonElement.click();
            },
          };
          await feildblanck();
          closeButtonObject.click();
          closeEditBookingModal(false)
        } catch (err) {
          handleErrorResponse(err);
        } finally {
          setLoader(false);
        }
      }
    }
  };

  const handleClearPreviousValue = () => {
    setFormValues({
      branchName: '',
      subDistrict: '',
      postalCode: '',
      country: '',
      state: '',
      city: '',
      address: '',
      shopImages: [],
      selectedImageArray: [],
      is_enabled: true,
    });
    setAddressFormValue((prev) => ({
      ...prev,
      state: { id: '', name: '', code: '' },
      city: { name: '' },
      postalCode: { value: '' }
    }))
  }

  const feildblanck = () => {
    setFormValues(() => ({
      branchName: "",
      subDistrict: "",
      postalCode: "",
      country: "",
      state: "",
      city: "",
      address: "",
      shopImages: [],
      selectedImageArray: [],
      is_enabled: true,
    }));
    setAddressFormValue((prev) => ({
      ...prev,
      state: { id: '', name: '', code: '' },
      city: { name: '' },
      postalCode: { value: '' }
    }))
    setFormErrors(() => { });
    setSelectedImages([]);
    setSelectedImage(null);
    setSelectedItems([])
  };

  const appendData = (formValues) => {
    const formData = new FormData();
    formData.append('branch_name', formValues?.branch_name);
    formData.append('postal_code', formValues?.postal_code);
    formData.append('district', formValues?.district);
    formData.append('address', formValues.address);
    formData.append('is_enabled', formValues.is_enabled === false ? true : false);
    if (addressFormValue?.country?.name !== 'Thailand') {
      formData.append('state', formValues?.state);
    }
    return formData;
  };


  const validate = (values) => {
    const errors = {
      branchName: '',
      country: '',
      state: '',
      city: '',
      subDistrict: '',
      address: '',
      postalCode: ''
    };
    if (!values.branchName) {
      errors.branchName = `${t('field.pleaseEnter')} ${t('vendor.branchName')}`;
    }
    if (addressFormValue?.country?.name !== 'Thailand') {
      if (!addressFormValue.state?.name) {
        errors.state = `${t('field.pleaseSelect')} ${t('field.state')}`;
      }
    }
    if (!addressFormValue.city?.name) {
      errors.city = `${t('field.pleaseSelect')} ${(addressFormValue?.country?.name !== 'Thailand') ? t('field.city') : t('field.subDistrict',)}`;
    }
    if (!values.address) {
      errors.address = `${t('field.pleaseEnter')} ${t('field.address')}`;
    }
    if (!addressFormValue?.postalCode?.value) {
      errors.postalCode = `${t('field.pleaseSelect')} ${t('financeField.postalCode')}`
    }
    return errors;
  };

  const appendData1 = (formValues) => {
    const formData = new FormData();
    formData.append('branch_name', formValues.branchName);
    formData.append('postal_code', addressFormValue?.postalCode?.value);
    formData.append('district', addressFormValue?.city?.name);
    formData.append('address', formValues.address);
    formData.append('is_enabled', formValues.is_enabled);
    formData.append('service_ids[]', 570)
    if (formValues.selectedImageArray) {
      for (const obj of formValues.selectedImageArray) {
        formData.append('images[]', obj);
      }
    }
    if (addressFormValue?.country?.name !== 'Thailand') {
      formData.append('state', addressFormValue?.state?.name);
    }
    return formData;
  };

  return (
    <>
      <Branches
        feildblanck={feildblanck}
        isShowEmptyPage={isShowEmptyPage}
        isOrganizationStatus={isOrganizationStatus}
        loader={loader}
        totalPage={totalPage}
        handlePaginationChange={handlePaginationChange}
        handleChangeFilter={handleChangeFilter}
        filterFormValue={filterFormValue}
        setFilterFormValue={setFilterFormValue}
        setFormErrors={setFormErrors}
        formErrors={formErrors}
        setFormValues={setFormValues}
        formValues={formValues}
        setSelectedImages={setSelectedImages}
        setSelectedImage={setSelectedImage}
        selectedImage={selectedImage}
        selectedImages={selectedImages}
        handleSubmit={handleSubmit}
        handleImageUpload={handleImageUpload}
        handleDeselectImage={handleDeselectImage}
        handleChange={handleChange}
        data={data}
        handleStatusChange={handleStatusChange}
        addressFormValue={addressFormValue}
        setAddressFormValue={setAddressFormValue}

      />
    </>
  );
};
export default Branch;