import './CheckOutHeader.css';
import { useEffect, useState, Link, Locale } from '../../libraries';
import { SuraLogo, SuraMobileLogo } from '../../assets/Images';
import { CustomImage } from '../../common';
import { HomeRoutes } from '../../routes/index';

const CheckOutHeader = ({ formValue }) => {
  const { t } = Locale();
  const [isDeliveryAdd, setIsDeliveryAdd] = useState({
    stepAactive: true,
    stepDone: false
  });
  const [isPaymentDetails, setIsPaymentDetails] = useState({
    stepTodo: true,
    stepAactive: false,
    stepDone: false

  })
  useEffect(() => {
    if ((formValue?.address) && (((formValue?.phoneNumber)?.split("").length >= 8))) {
      setIsDeliveryAdd((prev) => ({
        ...prev,
        stepAactive: false,
        stepDone: true
      }))
      setIsPaymentDetails((prev) => ({
        ...prev,
        stepTodo: false,
        stepAactive: true,
      }))
    }
    else {
      setIsDeliveryAdd((prev) => ({
        ...prev,
        stepAactive: true,
        stepDone: false
      }))
      setIsPaymentDetails((prev) => ({
        ...prev,
        stepTodo: true,
        stepAactive: false,
      }))
    }
    if ((formValue?.paymentType) && (formValue?.termAndConditon) && (isDeliveryAdd?.stepDone)) {
      setIsDeliveryAdd((prev) => ({
        ...prev,
        stepAactive: false,
        stepDone: true
      }))
      setIsPaymentDetails((prev) => ({
        ...prev,
        stepTodo: false,
        stepAactive: false,
        stepDone: true
      }))
    }
  }, [formValue])
  const isAddressClassName = (isDeliveryAdd?.stepAactive) ? 'step-active' : 'step-done'
  const isPaymentDetailsClassName = (isPaymentDetails?.stepTodo) ? 'step-todo' : (isPaymentDetails?.stepAactive) ? 'step-active' : 'step-done'
  return (
    <div className='header'>
      <div className='checkout-header-navbar'>
        <div className='row align-items-center'>
          <div className='col-4'>
            <div className="logo-section">
              <Link to={HomeRoutes.home}>
                <CustomImage
                  className="sura-logo img-fluid mobile-logo-img-tag"
                  src={SuraMobileLogo}
                  alt="logo"
                  height={'53px'}
                  width={'50px'}
                />
                <CustomImage
                  className="sura-logo img-fluid sura-log-web"
                  src={SuraLogo}
                  alt="logo"
                  height={'53px'}
                  width={'100px'}
                />
              </Link>
            </div>
          </div>
          <div className='col-8'>
            <div className="progress-container">
              <ol id="progress-bar">
                <li className={isAddressClassName}>{t('CheckOutHeader.deliveryAddress')}</li>
                <li className={isPaymentDetailsClassName}>{t('CheckOutHeader.paymentDetails')}</li>
                <li className="step-todo">{t('CheckOutHeader.finish')}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOutHeader;