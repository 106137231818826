import { React, useLocation, useNavigate, useEffect, useState} from '../../libraries';
import { BookNowMoadl } from '../../pages/BookNowModal/BookNowModal';
import { encryptData } from '../../customHooks/index.js';
import { CustomerRoutes } from '../../routes/index.js';
import moment from 'moment-timezone'; 

import { getCalculationPickAndTotalAmount, handleErrorResponse } from '../../services';
const BookNowModalLogic = ({
  index,
  type,
  className,
  service_id,
  vender_details,
  service_details,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(
    vender_details?.service_at_bussiness_location ? 'at_shop' : 'at_my_location'
  );
  const [selectedSlotTime, setSelectedSlotTime] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState();
  const [openBookNowModal, setOpenBookNowModal] = useState(false);
  const [confirmBtnDisable, setConfirmBtnDisable] = useState(true);
  const [organizationName, setOrganizationName] = useState(type === 'AddToFavourite' ? vender_details?.vendor_name : null);
  let serviceBranch= type === 'AddToFavourite' ? vender_details?.branch_details : null

  const [vendorOpeningHours, setVendorOpeningHours] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const role = localStorage.getItem('role');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const businessHoursKey = type === 'AddToFavourite' ? 'vendor_business_hours' : 'bussiness_hours';
    if (vender_details && vender_details[businessHoursKey]) {
      const businessHours = vender_details[businessHoursKey];

      setVendorOpeningHours({
        monday: businessHours.monday?.shop_open === 'true',
        tuesday: businessHours.tuesday?.shop_open === 'true',
        wednesday: businessHours.wednesday?.shop_open === 'true',
        thursday: businessHours.thursday?.shop_open === 'true',
        friday: businessHours.friday?.shop_open === 'true',
        saturday: businessHours.saturday?.shop_open === 'true',
        sunday: businessHours.sunday?.shop_open === 'true',
      });
    } else {
      setVendorOpeningHours({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      });
    }

    if (type !== 'AddToFavourite') {
      setOrganizationName(vender_details?.venue_name)
      let tempOption = [{ value: null, label: vender_details?.venue_name }];
      if (index) {
        service_details[index]?.branch_details?.map((obj) => (
          tempOption.push({ value: obj?.id, label: obj?.name })
        ))

      }
      else {
        service_details?.[0]?.branch_details?.map((obj) => (
          tempOption.push({ value: obj?.id, label: obj?.name })
        ))
      }
      setBranchOptions(tempOption);
    }

    generateDefaultTimeSlots();
  }, [type, index, vender_details]);

  useEffect(() => {
    if (serviceBranch) {
      let tempOption = [{ value: null, label: organizationName }];
      serviceBranch?.map((obj) => (
        tempOption.push({ value: obj?.id, label: obj?.name })))
      setBranchOptions(tempOption);
    }
  }, [serviceBranch])

  const showBookModal = () => {
    if (role === 'Customer') {
      setIsModalVisible(true);
      setOpenBookNowModal(!openBookNowModal);
    } else {
      setShowModal(true);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShowModal(false);
    setSelectedDate(null);
    setSelectedSlotTime(null)
    setConfirmBtnDisable(true);
  };

  const handleLocationChange = (value) => {
    setSelectedLocation(value);
  };

  const handleBranchChange = (value) => {
    setSelectedBranch(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.removeItem('isSection');
    const booking_date = selectedDate;
    const booking_time = selectedSlotTime;
    const serviceId = type ? vender_details.service_id : service_details?.[index ?? 0]?.service_id ||  service_details?.id;
    const dataToEncrypt = {
      serviceId,
      booking_date,
      booking_time,
      selectedLocation,
      branchId: selectedBranch
    };
    const encryptedData = encryptData(dataToEncrypt);
    handleCalculationApi(encryptedData, serviceId, booking_date, booking_time, selectedBranch)
  };

  const handleCalculationApi = async (encryptedData, serviceId, booking_date, booking_time, branchId) => {
    try {
      let bookingDetails;
      bookingDetails = [
        {
          service_id: serviceId,
          booking_date: booking_date,
          booking_time: booking_time,
          branch_id: branchId && branchId
        },
      ];

      let param = { booking_service_details: bookingDetails, checkout_payload: encryptedData };
      let response = await getCalculationPickAndTotalAmount(param);
      navigate(`${CustomerRoutes.payment}/${response.data.unique_checkout_url}`);
    }
    catch (err) {
      handleErrorResponse(err)
    }
  }

  const disabledDate = (current) => {
    if (current < moment().startOf('day')) {
      return true;
    }
    const dayName = current.format('dddd').toLowerCase();
    return !vendorOpeningHours[dayName];
  };
  const isFavouritePage = location.pathname === `${CustomerRoutes.user}${CustomerRoutes.favouriteService}`;
  const handleCalendarDateChange = (date, vender_details) => {
    if (date) {
      setSelectedDate(date.format("DD/MM/YYYY"))
      generateTimeSlots(date, vender_details)
    }
  };
  const generateTimeSlots = (date, vender_details) => {
    const selectedDayName = date.format('dddd').toLowerCase(); 
    const keyName = isFavouritePage ? 'vendor_business_hours' : 'bussiness_hours'
    const dayDetails = vender_details?.[keyName]?.[selectedDayName];
    if (!dayDetails || dayDetails.shop_open === "false") {
      return [];
    }
    const openTime = dayDetails.open; 
    const closeTime = dayDetails.close; 
    const timeSlots = [];
    const currentTime = moment(openTime, 'HH:mm');
    const endTime = moment(closeTime, 'HH:mm');

    while (currentTime.isBefore(endTime)) {
      timeSlots.push(currentTime.format('HH:mm'));  
      currentTime.add(15, 'minutes'); 
    }
    setTimeSlots(timeSlots)
  };

  const generateDefaultTimeSlots = () => {
    const timeSlots = [];
    let startTime = moment().startOf('day'); 
    while (startTime.format('HH:mm') !== '00:00' || timeSlots.length === 0) {
      timeSlots.push(startTime.format('HH:mm'));
      startTime.add(15, 'minutes'); 
    }
    setTimeSlots(timeSlots)
  }

  const handleTimeSlotClick = (slot) => {
    setSelectedSlotTime(slot);
    setConfirmBtnDisable(false)
  }

  return (
    <BookNowMoadl
      organizationName={organizationName}
      selectedBranch={selectedBranch}
      branchOptions={branchOptions}
      handleBranchChange={handleBranchChange}
      selectedLocation={selectedLocation}
      confirmBtnDisable={confirmBtnDisable}
      vender_details={vender_details}
      vendorOpeningHours={vendorOpeningHours}
      openBookNowModal={openBookNowModal}
      showBookModal={showBookModal}
      isModalVisible={isModalVisible}
      showModal={showModal}
      className={className}
      uniqueId={`date-popup-${service_id}`}
      handleCancel={handleCancel}
      handleOk={handleSubmit}
      handleLocationChange={handleLocationChange}
      disabledDate={disabledDate}
      serviceLocationHome={vender_details?.service_at_customer_location}
      serviceLocationShop={vender_details?.service_at_bussiness_location}
      setShowModal={setShowModal}
      selectedDate={selectedDate}
      selectedSlotTime={selectedSlotTime}
      handleCalendarDateChange={handleCalendarDateChange}
      timeSlots={timeSlots}
      handleTimeSlotClick={handleTimeSlotClick}
    />
  );
};

export default BookNowModalLogic;