import { React, Cropper } from '../../libraries';

const ImageCropper = ({
  uploadImage,
  onCropComplete,
  crop,
  zoom,
  setZoom,
  setCrop,
  handleChange,
  className,
}) => {
  return (
    <div>
      <input
        type="file"
        onChange={handleChange}
        accept="image/jpeg, image/png, image/jpg"
        className={className}
      />
      {uploadImage && (
        <Cropper
          image={uploadImage}
          crop={crop}
          zoom={zoom}
          aspect={4 / 3}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          handleChange={handleChange}
          accept={['JPG', 'PNG', 'JPEG', 'WEBP']}
          className={className}
        />
      )}
    </div>
  );
};

export default ImageCropper;
