import './BookNowModal.css'; // Import your CSS file
import { React, dayjs, utc, Locale, useEffect, useState, Modal, Button, DatePicker, Select } from '../../libraries';
import { SignIn } from '../../lazyComponent'; 

export const BookNowMoadl = ({
  disabledDate,
  confirmBtnDisable,
  vendorOpenignHoursFunction,
  isModalVisible,
  showModal,
  className,
  showBookModal,
  handleOk,
  handleCancel,
  uniqueId,
  handleLocationChange,
  vender_details,
  serviceLocationHome,
  serviceLocationShop,
  setShowModal,
  selectedLocation,
  handleBranchChange,
  branchOptions, organizationName,
  selectedBranch,
  handleCalendarDateChange,
  timeSlots,
  selectedSlotTime,
  handleTimeSlotClick,
  selectedDate
}) => {
  const { t } = Locale();
  dayjs.extend(utc);
  const [options, setOptions] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 445);
  const setLocationOption = () => {
    const options = [];

    if (serviceLocationHome) {
      options.push({ value: 'at_my_location', label: t('field.atHome') });
    }
    if (serviceLocationShop) {
      options.push({ value: 'at_shop', label: t('field.atShop') });
    }

    setOptions(options);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 445);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await vendorOpenignHoursFunction();
      } catch (error) { }
    };

    fetchData();
    setLocationOption();
  }, [serviceLocationHome, serviceLocationShop]);

  const selectedDateCalendarFormat = selectedDate ? dayjs(selectedDate, "DD-MM-YYYY") : null;

  return (
    <>
      <div className="bookNow book-now-modal-new">
        <div
          className={`modal fade ${showModal ? 'show d-block' : 'd-none'}`}
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <SignIn setShowModal={setShowModal} showModal={showModal} />
        </div>
      </div>
      <Button onClick={showBookModal} className={`book-now-modal-book-btn ${className}`}>
        {t('button.bookNowButton')}
      </Button>
      <Modal
        title={t('field.scheduleDate')}
        open={isModalVisible}
        className="add-to-cart-modal book-now-modal-date-picker"
        footer={[
          <Button key="1" onClick={handleCancel}>
            {t('button.cancelButton')}
          </Button>,
          <Button
            key="3"
            className={`confirm ${confirmBtnDisable && 'btn-disable'}`}
            onClick={(e) => handleOk(e)}
          >
            {t('field.confirm')}
          </Button>,
        ]}
      >

        <div className="row ime-pick-btn book-now-modal-date-picker-outer-div book-now-modal-single-booking">
          <div className="col-12 book-now-modal-calendar-col-9 hide-book-btn-modal-new">
            <div className="d-flex justify-content-between">
              <div>
                <Select
                  defaultValue={
                    serviceLocationShop
                      ? t('userBooking.atShop')
                      : t('field.atHome')
                  }
                  onChange={handleLocationChange}
                  options={options}
                  style={{ width: 120 }}
                />
              </div>
              <div className={`${(((selectedLocation === 'at_shop') && (selectedBranch))) || ((selectedLocation === 'at_shop') && organizationName) ? 'branch-name-book-modal' : 'd-none'}`}>
                <div className='branch-name-heading'>
                 {t('vendor.branch')} :-
                </div>
                <Select
                  defaultValue={selectedBranch ? selectedBranch : organizationName}
                  onChange={handleBranchChange}
                  options={branchOptions}
                  style={{ width: 160 }}
                />
              </div>
            </div>
            <div className="row time-pick-btn book-now-modal-date-picker-outer-div">
              <div className="col-md-9 col-sm-12 custom-datepicker-div book-now-modal-calendar-col-9">
                <DatePicker
                  key={isModalVisible}
                  className="custom-datepicker book-now-modal-date-picker"
                  getPopupContainer={() => document.getElementById(uniqueId)}
                  popupStyle={{
                    position: "relative",
                  }}
                  onChange={(date) => handleCalendarDateChange(date, vender_details)}
                  open={true}
                  showNow={false}
                  disabledDate={disabledDate}
                  value={selectedDateCalendarFormat}
                />
                <div id={uniqueId} />
              </div>
              <div className="col-md-3 col-sm-12 book-now-modal-calendar-col-3">
                {!isMobileView && <>
                  <div className="pick-time-container mt-3">
                    {timeSlots && timeSlots.map((timeSlot, index) => (
                      <div key={index} className={`${!selectedDate && 'disable-time-slot'} pick-time mb-1 ${selectedSlotTime === timeSlot ? "selected-time" : ""}`}
                        onClick={() => handleTimeSlotClick(timeSlot)}>
                        {timeSlot}
                      </div>
                    ))}
                  </div>
                </>}
                {isMobileView && <>
                  <div className="pick-time-container mt-3 grid-container">
                    {timeSlots && timeSlots.map((timeSlot, index) => (
                      <div key={index} className={`${!selectedDate && 'disable-time-slot'} pick-time mb-1 ${selectedSlotTime === timeSlot ? "selected-time" : ""}`}
                        onClick={() => handleTimeSlotClick(timeSlot)}>
                        {timeSlot}
                      </div>
                    ))}
                  </div>
                </>}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};