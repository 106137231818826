 const CustomNextArrow=(props) =>{
    const { className, style, onClick } = props;
    return (
      <div
        className={`custom-next-arrow ${className}`}
        style={{ ...style, display: 'block', top: '103%', left: '52%', right: 'unset', transform: 'unset' }}
        onClick={onClick}
      >
        Next
      </div>
    );
  }
  export default CustomNextArrow;