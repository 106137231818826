
export const isValidEmail1 = (email, checkErr, key, t) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) {
    checkErr[key] = `${t('field.pleaseEnter')} ${t('field.email')}`;
    return checkErr;
  }
  if (!emailRegex.test(email)) {
    checkErr[key] = 'Please Enter Valid Email';
  }
  return checkErr;
};

export const isValidFirstName = (value, checkErr, key, keyValue, t) => {
  const nameRegex = /^[A-Za-z- ]+$/;
  if (!value) {
    checkErr[key] = `${t('field.pleaseEnter')} ${keyValue}`;
    return checkErr;
  }
  if (!nameRegex.test(value)) {
    checkErr[key] = `Please Enter Valid ${keyValue}`;
  }

  return checkErr;
};

export const isValidPhoneNumber = (
  phoneNumber,
  error,
  key,
  keyValue,
  notrequired,
  t,
) => {
  const phoneNumberRegex = /^[\d()-]*\d[\d()-]*$/;
  const cleanPhoneNumber =
    typeof phoneNumber === 'string' ? phoneNumber.replace(/\D/g, '') : '';

  if (notrequired) {
    let phoneRegex = /^[0-9]{8,10}$/;
    if (phoneNumber) {
      if (!phoneRegex.test(cleanPhoneNumber)) {
        error[key] = `${t('validation.phoneMust')}`;
      }
    }

    return error;
  }

  if (!phoneNumber) {
    if (keyValue) {
      error[key] = `${t('field.pleaseEnter')} ${keyValue}`;
    } else {
      error[key] = `${t('field.pleaseEnter')} ${t('field.phoneNumber')}`;
    }
    return error;
  }

  if (
    !phoneNumberRegex.test(phoneNumber) ||
    cleanPhoneNumber.length < 8 ||
    cleanPhoneNumber.length > 10
  ) {
    error[key] = `${t('validation.phoneMust')}`;
    return error;
  }

  return error;
};

export const isValidDescription = (value, checkErr, key, keyValue, t) => {
  const strippedValue = value?.replace(/(<([^>]+)>)/gi, '').trim();
  if (!strippedValue) {
    checkErr[key] = `${t('field.pleaseEnter')} ${keyValue}`;
  }
  return checkErr;
};

export const isRequired = (value, checkErr, key, keyValue, t) => {
  if (!value) {
    checkErr[key] = `${t('field.pleaseEnter')} ${keyValue}`;
  }
  return checkErr;
};
export const isValidNumber = (value, checkErr, key, keyValue, t) => {
  if (!value) {
    checkErr[key] = `${t('field.pleaseEnter')} ${keyValue}`;
  } else if (!/^\d+$/.test(value)) {
    checkErr[key] = `Please Enter Valid Number For ${keyValue}`;
  }
  return checkErr;
};

export const isValidPostCode = (postCode, checkErr, t) => {
  const postalCodeRegex = /^\d{5}$/;

  if (!postCode) {
    checkErr.postCode = `${t('field.pleaseSelect')} ${t('field.postalCode')}`;
    return checkErr;
  }
  if (!postalCodeRegex.test(postCode))
    checkErr.postCode = 'Please Enter Valid Post Code';
  return checkErr;
};



export const isRequiredCheckbox = (value, checkErr, key, keyValue, t) => {
  if (keyValue && value === 'null') {
    checkErr[key] = `${t('field.pleaseSelect')} ${keyValue}`;
  }
  if (!value) {
    if (keyValue && value === 'null') {
      checkErr[key] = `${t('field.pleaseSelect')} ${keyValue}`;
    } else {
      checkErr[key] = `${t('field.pleaseSelect')} ${key}`;
    }
  }
  return checkErr;
};
export const multiOptionRequiredCheckbox = (
  value1,
  value2,
  checkErr,
  key,
  keyValue,
  t,
) => {
  if (!value1 && !value2) {
    if (keyValue) {
      checkErr[key] = `${t('field.pleaseSelect')} ${keyValue}`;
    } else {
      checkErr[key] = `${t('field.pleaseSelect')} ${key}`;
    }
  }
  return checkErr;
};
export const isValidOpeningHourse = (bussiness_hours, checkErr, t) => {
  let openDay = [];
  if (bussiness_hours) {
    const anyDayWithValidHours = Object.entries(bussiness_hours).some(
      ([day, details]) =>
        details.shop_open === true || details.shop_open === 'true',
    );

    if (!anyDayWithValidHours) {
      checkErr.bussiness_hours = `${t('validation.leastOne')}`;
    } else {
      openDay = Object.entries(bussiness_hours)
        .filter(
          ([day, details]) =>
            details.shop_open === true || details.shop_open === 'true',
        )
        .map(([day, details]) => day);

      for (let i = 0; i < openDay.length; i++) {
        const dayDetails = bussiness_hours[openDay[i]];
        if (
          !dayDetails.open ||
          dayDetails.open === 'undefined' ||
          !dayDetails.close ||
          dayDetails.close === 'undefined'
        ) {
          checkErr.bussiness_hours = `${t('validation.selectTime')}`;
          break;
        }
      }
    }
  } else {
    checkErr.bussiness_hours = `${t('validation.leastOne')}`;
  }

  return checkErr;
};

export const isValidNumberField = (value, checkErr, key, t) => {
  return checkErr;
};

export const isValidBranchName = (value, checkErr, key, keyValue, t) => {
  const nameRegex = /^[A-Za-z- ]+$/;
  if (!value) {
    checkErr[key] = `${t('field.pleaseEnter')} ${keyValue}`;
    return checkErr;
  }
  if (!nameRegex.test(value)) {
    checkErr[key] = `${t('field.pleaseEnter')} ${keyValue}`;
  }
  return checkErr;
};

export const isRequiredSelect = (value, checkErr, key, keyValue, t) => {
  if (!value || value === 'Select') {
    checkErr[key] = `${t('field.pleaseSelect')} ${keyValue}`;
  }
  return checkErr;
};

export const isRequiredImage = (value, checkErr, key, keyValue, value2, t) => {
  if (!value2) {
    if (!value) {
      if (keyValue) {
        checkErr[key] =
          `${t('vendor.please')} ${t('vendor.upload')} ${keyValue}`;
      } else {
        checkErr[key] =
          `${t('vendor.please')} ${t('vendor.upload')} ${t('vendor.image')}`;
      }
    }
  }
  if (value2) {
    if (!value && !value2) {
      if (keyValue) {
        checkErr[key] =
          `${t('vendor.please')} ${t('vendor.upload')} ${keyValue}`;
      } else {
        checkErr[key] =
          `${t('vendor.please')} ${t('vendor.upload')} ${t('vendor.image')}`;
      }
    }
  }
  return checkErr;
};

export const isRequiredCheckbox1 = (value, checkErr, key, t) => {
  if (!value) {
    checkErr[key] = `${t('validation.clickCheckbox')}`;
  }
  return checkErr;
};

export const isRequiredRadioButton = (value, checkErr, key, t) => {
  if (!value) {
    checkErr[key] = `${t('field.pleaseSelect')} ${t('vendor.paymentOption')}`;
  }
  return checkErr;
};