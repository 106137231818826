import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { AuthRoutes, HomeRoutes } from '../routes/index';
import { fetchIpBasedLocation } from '../customHooks/index.js';
import { logApiTime } from '../util/loger.js';

const baseUrl = process.env.REACT_APP_BASE_URL;


const getContry = async () => {
  if ((!sessionStorage.getItem('country') || sessionStorage.getItem('country') === 'null')) {
    const country = await fetchIpBasedLocation();
    return country
  }
  return sessionStorage.getItem('country');
}

const headers = async (token, isFormData) => {
  const country = await getContry();

  const baseHeaders = {
    Accept: 'application/json',
    Authorization: token,
    Language: localStorage.getItem('i18nextLng'),
    Country: country,
    Latitude: JSON.parse(sessionStorage.getItem('location'))?.latitude,
    Longitude: JSON.parse(sessionStorage.getItem('location'))?.longitude,
  };

  if (isFormData) {
    return {
      ...baseHeaders,
      'Content-Type': 'multipart/form-data',
    };
  } else {
    return {
      ...baseHeaders,
      'Content-Type': 'application/json',
    };
  }
};

const apiCall = async (path, method, body = {}) => {
  const url = `${baseUrl}${path}`;
  const token = localStorage.getItem('access_token');
  const isFormData = body instanceof FormData;

  const config = {
    method,
    url,
    headers: await headers(token, isFormData),
    data: body,
  };

  const startTime = performance.now(); // API Start Time
  try {
    const response = await axios(config);
    const endTime = performance.now(); // API End Time
    const duration = (endTime - startTime).toFixed(2); // API Execution Time in ms

    // Log API response time with unique key
    logApiTime(method, path, duration);
    return response;

  } catch (error) {
    const endTime = performance.now();
    const duration = (endTime - startTime).toFixed(2);
    logApiTime(method, path, duration, true);
    throw error;
  }
};

const handleSuccessResponse = (response, successMessage = null) => {
  if ([200, 201].includes(response.status)) {
    const message = successMessage || response.data.toast;
    toast.success(message);
  } else {
    handleErrorResponse(response);
  }
};

const persistLanguageSettings = () => {
  const i18nextLng = localStorage.getItem('i18nextLng');
  const selectedLanguage = localStorage.getItem('selectedLanguage');
  localStorage.clear();

  if (i18nextLng) {
    localStorage.setItem('i18nextLng', i18nextLng);
  }
  if (selectedLanguage) {
    localStorage.setItem('selectedLanguage', selectedLanguage);
  }
};

const handleNavigation = (redirectToSignIn = false) => {
  persistLanguageSettings();
  window.location.href = redirectToSignIn ? AuthRoutes.signIn : HomeRoutes.home;
};

const handleStatusError = (status, error) => {
  const expiredToken = error?.response?.data?.status_code === 403
  const errorMessage = error?.response?.data?.error || error?.response?.data?.toast;

  if (expiredToken) {
    handleNavigation(true)
  }
  else if (errorMessage) {
    toast.error(errorMessage);
  }
  else {
    switch (status) {
      case 400:
      case 404:
        toast.error(errorMessage);
        break;
      case 401:
      case 405:
      case 409:
      case 500:
        toast.error(errorMessage);
        break;
      case 422:
        persistLanguageSettings();
        window.location.replace('/sign_in');
        break;
      case 403:
        handleNavigation(true);
        break;
      default:
        toast.error('An unexpected error occurred');
    }
  }
};

const handleErrorResponse = (error, message) => {
  const isInvalidToken = error?.response?.data?.status_code === 404;
  if (isInvalidToken) {
    toast.error(error?.response?.data?.error)
    handleNavigation(true)
  }
  if (message) {
    toast.error(message);

  }
  else if (error?.response) {
    const { status } = error.response;

    if (status === 404 || status === 403) {
      handleStatusError(status, error);
    } else if (error.response.data?.error) {
      handleStatusError(status, error);
    } else {
      toast.error('Unknown error occurred');
    }
  } else {
    toast.error(error.message || 'Unknown error occurred');
  }
};


export { apiCall, handleSuccessResponse, handleErrorResponse };