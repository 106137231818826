import { React, forwardRef, useState, useNavigate } from '../../libraries/index';
import { SearchField } from '../../pages';
import { handleErrorResponse } from '../../services/index';
import { HomeRoutes } from '../../routes/index.js';
const NavbarSearch = forwardRef((props) => {
  const { expand, className, value, type, name, placeholder, setIsActiveSearch } = props;
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const handleSubmit = async () => {
    try {
      localStorage.removeItem('isSection');
      navigate(`${HomeRoutes.services}/search/${data.search}`);
    } catch (err) {
      handleErrorResponse(err);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ [name]: value });
  };
  return (
    <SearchField
      setIsActiveSearch={setIsActiveSearch}
      expand={expand}
      className={className}
      value={value}
      name={name}
      type={type}
      placeholder={placeholder}
      onChange={handleChange}
      onSubmit={handleSubmit}
    ></SearchField>
  );
},
);
export default NavbarSearch;