import React from 'react';
import { Locale,useState,useEffect } from '../../libraries';
import { fetchUserCurrentLocation } from '../../customHooks';
import { PermissionDenied } from '../../pages';

const LocationChecker = ({ path, onComplete }) => {
  const { t } = Locale();
  const [showModalPermissionDeniedModal, setShowModalPermissionDeniedModal] = useState(false);

  useEffect(() => {
    requestLocation();
  }, [path]);

  const requestLocation = async () => {
    const userConsent = window.confirm(t('header.AllowCurrentlocationMessage'));

    if (!userConsent) {
      alert(t('header.AllowCurrentlocationMessage'));
      onComplete(false);
      return;
    }

    try {
      await fetchUserCurrentLocation();
      onComplete(true);
    } catch (error) {
      setShowModalPermissionDeniedModal(true);
    }
  };

  const handleClosePermissionModal = () => {
    setShowModalPermissionDeniedModal(false);
    onComplete(false);
  };

  return (
    <>
      {showModalPermissionDeniedModal && (
        <PermissionDenied
          showModal={showModalPermissionDeniedModal}
          handleClose={handleClosePermissionModal}
        />
      )}
    </>
  );
};

export default LocationChecker;