import { React, useLocation, useEffect } from '../../libraries';
import { handleErrorResponse, handleSuccessResponse, cancelDeleteUserRequest } from '../../services';
import { CancelDeletionRequest } from '../../pages';
import { BusinessRoutes } from '../../routes';

const CancelDeletionAction = ({ setDeletionRequest }) => {
  const location = useLocation();
  const isSection = localStorage.getItem('isSection');
  const isCustomer = localStorage.getItem('role') === 'Customer'

  useEffect(() => {
    if (isSection !== 'isProfile') {
      if (isCustomer) {
        localStorage.setItem('isSection', 'isProfile')
      }
      else {
        if (location.pathname === `${BusinessRoutes.bussiness}${BusinessRoutes.accountDetails}`)
          localStorage.setItem('isSection', 'isBusinessAccount');

        else if (location.pathname === `${BusinessRoutes.bussiness}${BusinessRoutes.finance}`)
          localStorage.setItem('isSection', 'isBusinessFinance');

        else
          localStorage.setItem('isSection', 'isVendorProfile');
      }
    }
  }, [location.pathname]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let param = {
      status: 'activated',
    };

    cancelDeleteUserRequest(param)
      .then((response) => {
        handleSuccessResponse(response, response.data.message);
        if (isCustomer) {
          localStorage.setItem('deletionRequestStatus', 'activated');
          localStorage.setItem('status', 'activated');

        }
        else {
          localStorage.setItem('deletionRequestStatus', 'activated');
        }
        setDeletionRequest(false);
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };

  return (
    <>
      <CancelDeletionRequest handleSubmit={handleSubmit} />
    </>
  );
};
export default CancelDeletionAction;